import React, {useEffect, useImperativeHandle, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, notification, Row, Select, Spin, Switch, Tooltip} from 'antd';
import {CheckOutlined, CloseOutlined, MailOutlined, PlusOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import moment from 'moment';
import ContactModal from '../../../../Components/ContactModal';
import OperatorModal from '../../../../Components/OperatorModal';
import './index.less';
import AjaxService from "../../../../Services/AjaxService";
import CaseTypeModal from "../../../../Components/CaseTypeModal";
import { debounce } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';

const BABForm = React.forwardRef((props: any, ref: any) => {

    let isCancelled: any = false;

    const [form] = Form.useForm();
    const {Option} = Select;

    const [formState, setFormState] = useState({
        ticket: false as any,
        ticketLoading: (props.action === 'edit'),
        contacts: [] as any,
        loadingContacts: false,
        contactModalVisible: false,
        selectedContact: false as any,
        operators: [] as any,
        loadingOperators: true,
        operatorModalVisible: false,
        caseStatuses: [] as any,
        loadingCaseStatuses: true,
        caseStatusSearch: '',
        types: [] as any,
        loadingTypes: true,
        typeModalVisible: false,
        users: [] as any,
        loadingUsers: true,
        complaints: [] as any,
        loadingComplaints: false,
        statusInfo: [] as any,
        statusInfoSearch: '',
        loadingStatusInfo: true
    });

    const typeAdded = (type: any) => {
        let selected_types = form.getFieldValue('meta:Types');
        Object.keys(type).forEach((code: any) => {
            formState.types.push({
                label: code + '. ' + type[code],
                value: code
            });
            selected_types.push(code);
        });

        setFormState({
            ...formState,
            types: [
                ...formState.types
            ],
            typeModalVisible: false
        });
        form.setFieldsValue({
            'meta:Types': [...selected_types]
        });
        notification.success({
            message: 'Add Type',
            description: `The type was successfully created.`
        });
    }

    const fetchStatusInfo = async () => {
        try {
            let response = await AjaxService.get(`cases/meta/`, {
                filter_case_type: 'bab',
                filter_key: 'Status Information'
            }, true);
            setFormState((previous: any) => {
                let items: any = ['Appellee Notified', 'Meeting', 'Decision', 'Issued'];
                response.data.forEach((item: any) => {
                    if (items.indexOf(item) === -1) {
                        items = items.concat(item);
                    }
                });
                items = items.filter((v: any, i: any, a: any) => v && a.indexOf(v) === i)
                    .sort((a: any, b: any) => a - b).map((v: any) => {
                        return {
                            label: v,
                            value: v
                        };
                    });
                return {
                    ...previous,
                    loadingStatusInfo: false,
                    statusInfo: items
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const contactAdded = (contact: any) => {
        formState.contacts.push(contact);
        setFormState({
            ...formState,
            contacts: [
                ...formState.contacts
            ],
            contactModalVisible: false
        });
        form.setFieldsValue({
            contact_id: contact.id.toString()
        });
        notification.success({
            message: 'Add Contact',
            description: `The contact was successfully created.`
        });
    }

    const operatorAdded = (operator: any) => {
        formState.operators.push({
            label: operator.title,
            value: operator.id
        });
        setFormState({
            ...formState,
            operators: [
                ...formState.operators
            ],
            operatorModalVisible: false
        });
        form.setFieldsValue({
            operator_id: operator.id
        });
        notification.success({
            message: 'Add Operator',
            description: `The operator was successfully created..`
        });
    }

    const fetchOperators = async () => {
        try {
            let response = await AjaxService.get(`operators/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingOperators: false,
                    operators: response.data.map((operator: any) => {
                        return {
                            label: operator.title,
                            value: operator.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchCaseStatuses = async () => {
        try {
            let response = await AjaxService.get(`case-statuses/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingCaseStatuses: false,
                    caseStatuses: response.data.map((status: any) => {
                        return {
                            label: status.title,
                            value: status.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchTypes = async () => {
        try {
            let response = await AjaxService.get(`case-types`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingTypes: false,
                    types: Object.keys(response.data).map((code: any) => {
                        return {
                            label: code + '. ' + response.data[code],
                            value: code
                        }
                    })
                }
            });
        } catch (e: any) {
            console.log(e);
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const searchContacts = async (search: any) => {
        if (!search) {
            return;
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingContacts: true,
                contacts: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`contacts`, {filter_query: search}, true);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingContacts: false,
                contacts: (response ? response.data : previous.contacts)
            };
        });
    }

    const searchComplaints = async (search: any) => {
        if (!search || search.length <= 4) {
            return;
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingComplaints: true,
                complaints: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`cases`, {
                filter_query: search,
                filter_type: 'complaint'
            }, true);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingComplaints: false,
                complaints: (response ? response.data : previous.complaints)
            };
        });
    }

    const fetchUsers = async () => {
        try {
            let response = await AjaxService.get(`users/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingUsers: false,
                    users: response.data.map((user: any) => {
                        return {
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchCase = async () => {
        let response: any;
        try {
            console.log('BAB/Form: fetching data...');
            response = await AjaxService.get(`cases/${props.id}`, {'include': ['meta']});
            form.setFieldsValue({
                contact_id: response.data.contact?.id.toString(),
                // contact: response.data.contact,
                'meta:Feedback Form Posted': (parseInt(response.data.meta['Feedback Form Posted'], 10) === 1),
                'meta:Types': (response.data.meta['Types'] ? Object.keys(response.data.meta['Types']) : []),
                operator_id: response.data.operator?.id,
                // operator: response.data.operator,
                location_id: (response.data.location ? [response.data.location.region_id, response.data.location.id] : null),
                // location: response.data.location,
                'meta:Status Information': response.data.meta['Status Information'],
                'meta:Complaint': response.data.meta['Complaint']?.id,
                status_id: response.data.status?.id,
                'meta:Contact': (response.data.meta['Contact'] ? response.data.meta['Contact'] : ''),
                'meta:Date Referred': (response.data.meta['Date Referred'] ? moment(response.data.meta['Date Referred']) : null),
                'meta:Received by Pax Rep': (response.data.meta['Received by Pax Rep'] ? moment(response.data.meta['Received by Pax Rep']) : null),
                'meta:Emailed to BAB Admin': (response.data.meta['Emailed to BAB Admin'] ? moment(response.data.meta['Emailed to BAB Admin']) : null),
                'meta:Date of CPT Response': (response.data.meta['Date of CPT Response'] ? moment(response.data.meta['Date of CPT Response']) : null),
                'meta:Date Decision Issued': (response.data.meta['Date Decision Issued'] ? moment(response.data.meta['Date Decision Issued']) : null),
                'meta:CPT Response': (response.data.meta['CPT Response'] ? response.data.meta['CPT Response'] : null),
                'meta:Response from SM': (response.data.meta['Response from SM'] ? response.data.meta['Response from SM'] : null),
                'meta:Summary of BAB Decision': (response.data.meta['Summary of BAB Decision'] ? response.data.meta['Summary of BAB Decision'] : null),
                'meta:Appellants Comments': (response.data.meta['Appellants Comments'] ? response.data.meta['Appellants Comments'] : null),
                assigned_to: response.data.assigned_to?.id
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                ticket: (response ? response.data : previous.ticket),
                ticketLoading: false
            }
        });
    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        form.resetFields();
        if (props.action === 'edit') {
            fetchCase();
        } else if (props.formState.extra !== false && props.formState.extra !== undefined && Object.keys(props.formState.extra).length > 0) {
            form.setFieldsValue({
                // contact: props.formState.extra?.contact,
                // location: props.formState.extra?.location,
                // operator: props.formState.extra?.operator,
                contact_id: props.formState.extra?.contact?.id.toString(),
                location_id: (props.formState.extra.location ? [props.formState.extra.location.region_id, props.formState.extra.location.id] : null),
                operator_id: props.formState.extra.operator?.id,
                assigned_to: props.formState.extra.assigned_to?.id,
                message: props.formState.extra.initial_response
            });
        }
        fetchOperators();
        fetchCaseStatuses();
        fetchUsers();
        fetchTypes();
        fetchStatusInfo();
        return () => {
            isCancelled = true;
        };
    }, []);

    const filterSelect = (input: any, option: any) => {
        let value = ('children' in option ? option.children : option.label);
        if (Array.isArray(value)) {
            value = value.join('');
        }
        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const onSubmit = async (values: any) => {

        try {
            let body = JSON.parse(JSON.stringify(values));
            body.type = 'bab';

            let response;
            if (props.action === 'edit') {
                response = await AjaxService.put(`cases/${props.id}`, body);
            } else {
                if (props.formState.extra && Object.keys(props.formState.extra).length > 0) {
                    body['meta:Complaint'] = props.formState.extra.id;
                }
                response = await AjaxService.post('cases', body);
            }
            props.onAdd(response.data.id, (response.data.reference ? response.data.reference : response.data.id), response.data.type.value, props.action);
        } catch (e: any) {
            notification.error({
                message: (props.action === 'edit' ? 'Edit' : 'Add') + ' BAB',
                description: 'There was an issue ' + (props.action === 'edit' ? 'updat' : 'creat') + 'ing the case. Please try again.'
            });
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loading: false
                };
            });
        }

    }

    useImperativeHandle(ref, () => ({
        submitForm() {
            form.submit();
        }
    }));

    return (
        <Spin spinning={formState.ticketLoading}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onSubmit}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        {/* <Form.Item
                          label="Appellant"
                          name="contact"
                          rules={[{required: false, message: 'Please enter the appellant!'}]}
                        >
                            <Input placeholder="Appellant"/>
                        </Form.Item> */}
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-requiredx">Appellant</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="contact_id"
                                rules={[{required: true, message: 'Please select a appellant or create a new one!'}]}
                                style={{width: "calc(100% - 32px)"}}
                            >
                                <Select
                                    placeholder="Search for a appellant"
                                    showSearch
                                    onSearch={debounce(searchContacts, 300)}
                                    filterOption={false}
                                    style={{width: '100%'}}
                                    notFoundContent={formState.loadingContacts ? <Spin size="small"/> : null}
                                    loading={formState.loadingContacts}
                                >
                                    {formState.contacts.length === 0 && props.action === 'edit' && formState.ticket?.contact && (
                                        <Option key={formState.ticket.contact.id.toString()}
                                            value={formState.ticket.contact.id.toString()}>
                                            <div className={formState.ticket.contact.has_open_cases ? 'has-open-cases' : ''}>
                                                {formState.ticket.contact.first_name} {formState.ticket.contact.last_name}
                                                <small style={{ display: 'block' }}>{formState.ticket.contact.email}</small>
                                            </div>
                                        </Option>
                                    )}
                                    {props.action === 'add' && props.formState.extra?.contact && (
                                        <Option key={props.formState.extra.contact.id.toString()}
                                            value={props.formState.extra.contact.id.toString()}>
                                            <div className={props.formState.extra.contact.has_open_cases ? 'has-open-cases' : ''}>
                                                {props.formState.extra.contact.first_name} {props.formState.extra.contact.last_name}
                                                <small style={{ display: 'block' }}>{props.formState.extra.contact.email}</small>
                                            </div>
                                        </Option>
                                    )}
                                    {formState.contacts.map((contact: any) => {
                                        return (
                                            <Option className={contact.has_open_cases ? 'has-open-cases' : ''} key={contact.id.toString()} value={contact.id.toString()}>
                                                {contact.first_name} {contact.last_name}
                                                <small style={{display: 'block'}}>{contact.email}</small>
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Tooltip title="Add Contact">
                                <Button icon={<PlusOutlined/>}
                                        onClick={() => setFormState({...formState, contactModalVisible: true})}/>
                            </Tooltip>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-requiredx">Types</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="meta:Types"
                                rules={[{required: false, message: 'Please select a type or create a new one!'}]}
                                style={{width: "calc(100% - 32px)"}}
                            >
                                <Select
                                    style={{width: '100%'}}
                                    showSearch
                                    mode="multiple"
                                    maxTagCount={4}
                                    filterOption={filterSelect}
                                    loading={formState.loadingTypes}
                                    placeholder="Search for a type"
                                    optionLabelProp="code"
                                    options={formState.types}
                                />
                            </Form.Item>
                            <Tooltip title="Add Type">
                                <Button icon={<PlusOutlined/>}
                                        onClick={() => setFormState({...formState, typeModalVisible: true})}/>
                            </Tooltip>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Status Information"
                            name="meta:Status Information"
                        >
                            <Select
                                style={{width: '100%'}}
                                showSearch
                                mode="multiple"
                                maxTagCount={3}
                                onSearch={(value: any) => setFormState({...formState, statusInfoSearch: value})}
                                filterOption={filterSelect}
                                loading={formState.loadingStatusInfo}
                                onChange={(value: any, option: any) => {
                                    if (option.key === 'custom') {
                                        setFormState({
                                            ...formState,
                                            statusInfoSearch: '',
                                            statusInfo: [
                                                ...formState.statusInfo, {
                                                    value: value,
                                                    label: value
                                                }
                                            ]
                                        });
                                        // needs to be appended.
                                        form.setFieldsValue({
                                            'meta:Status Information': value
                                        });
                                    }
                                }}
                                placeholder="Search for a status"
                            >
                                {formState.statusInfo && formState.statusInfo.map((status: any) => {
                                    return <Select.Option key={status.value}
                                                          value={status.value}>{status.label}</Select.Option>
                                })}
                                {formState.caseStatusSearch && (
                                    <Select.Option key="custom"
                                                   value={formState.caseStatusSearch}>Add &ldquo;{formState.caseStatusSearch}&rdquo;</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                        <Form.Item
                          label="Contact"
                          name="meta:Contact"
                          rules={[{required: false, message: 'Please enter the contact!'}]}
                        >
                            <Input type="email" placeholder="Contact"/>
                        </Form.Item>
                    </Col> */}
                    <Col span={12}>
                        {/* <Form.Item
                          label="Operator"
                          name="operator"
                          rules={[{required: false, message: 'Please enter the operator!'}]}
                        >
                            <Input placeholder="Operator"/>
                        </Form.Item> */}
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-requiredx">Operator</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="operator_id"
                                rules={[{required: false, message: 'Please select a operator or create a new one!'}]}
                                style={{width: "calc(100% - 32px)"}}
                            >
                                <Select
                                    placeholder="Search for a operator"
                                    style={{width: '100%'}}
                                    showSearch
                                    filterOption={filterSelect}
                                    loading={formState.loadingOperators}
                                    options={formState.operators}
                                />
                            </Form.Item>
                            <Tooltip title="Add Operator">
                                <Button icon={<PlusOutlined/>}
                                        onClick={() => setFormState({...formState, operatorModalVisible: true})}/>
                            </Tooltip>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Bus Users UK No."
                            name="meta:Complaint"
                            rules={[{required: false, message: 'Please select a complaint!'}]}
                        >
                            <Select
                                placeholder="Search for a complaint"
                                showSearch
                                onSearch={debounce(searchComplaints, 300)}
                                filterOption={false}
                                notFoundContent={formState.loadingComplaints ? <Spin size="small"/> : null}
                                loading={formState.loadingComplaints}
                            >
                                {formState.complaints.length === 0 && props.action === 'edit' && formState.ticket && formState.ticket.meta['Complaint'] && (
                                    <Option key={formState.ticket?.meta['Complaint'].id}
                                            value={formState.ticket?.meta['Complaint'].id}>
                                        <span
                                            className={`TrafficLight Is-${formState.ticket?.meta['Complaint'].traffic_light}`}/> {formState.ticket?.meta['Complaint'].reference} - {formState.ticket?.meta['Complaint'].contact.first_name} {formState.ticket?.meta['Complaint'].contact.last_name}
                                        {formState.ticket?.meta['Complaint'].operator && (<small
                                            style={{display: 'block'}}>{formState.ticket?.meta['Complaint'].operator.title}</small>)}
                                    </Option>
                                )}
                                {formState.complaints.map((item: any) => (
                                    <Option key={item.id} value={item.id}>
                                        <span
                                            className={`TrafficLight Is-${item.traffic_light}`}/> {item.reference} - {item.contact.first_name} {item.contact.last_name}
                                        <small style={{display: 'block'}}>{item.operator && item.operator.title}</small>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Date Referred"
                            name="meta:Date Referred"
                        >
                            <DatePicker style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Received by Pax Rep"
                            name="meta:Received by Pax Rep"
                        >
                            <DatePicker style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Emailed to BAB Admin"
                            name="meta:Emailed to BAB Admin"
                        >
                            <DatePicker style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div className="ant-form-item-label">
                            <label>Feedback Form Posted</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="meta:Feedback Form Posted"
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined/>} unCheckedChildren={<CloseOutlined/>}/>
                            </Form.Item>
                            <Button style={{marginLeft: '1rem'}} icon={<MailOutlined/>}>Send Feedback Request</Button>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Status"
                            name="status_id"
                            initialValue={1}
                            rules={[{required: true, message: 'Please select a status or create a new one!'}]}
                        >
                            <Select
                                style={{width: '100%'}}
                                showSearch
                                onSearch={(value: any) => setFormState({...formState, caseStatusSearch: value})}
                                filterOption={filterSelect}
                                loading={formState.loadingCaseStatuses}
                                onChange={(value: any, option: any) => {
                                    if (option.key === 'custom') {
                                        setFormState({
                                            ...formState,
                                            caseStatusSearch: '',
                                            caseStatuses: [
                                                ...formState.caseStatuses, {
                                                    value: value,
                                                    label: value
                                                }
                                            ]
                                        });
                                        form.setFieldsValue({
                                            'status_id': value
                                        });
                                    }
                                }}
                                placeholder="Search for a status"
                            >
                                {formState.caseStatuses && formState.caseStatuses.map((status: any) => {
                                    return <Select.Option key={status.value}
                                                          value={status.value}>{status.label}</Select.Option>
                                })}
                                {formState.caseStatusSearch && (
                                    <Select.Option key="custom"
                                                   value={formState.caseStatusSearch}>Add &ldquo;{formState.caseStatusSearch}&rdquo;</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Assigned To"
                            name="assigned_to"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Search for a user"
                                filterOption={filterSelect}
                                loading={formState.loadingUsers}
                                options={formState.users}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <hr />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Date of CPT Response"
                            name="meta:Date of CPT Response"
                        >
                            <DatePicker style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="CPT Response"
                            name="meta:CPT Response"
                        >
                            <TextArea rows={6} placeholder="Enter the CPT Response"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <hr />
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="PAX Rep Response"
                            name="meta:Response from SM"
                        >
                            <TextArea rows={6} placeholder="Enter the PAX Rep Response"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <hr />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Date of BAB Decision"
                            name="meta:Date Decision Issued"
                        >
                            <DatePicker style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Summary of BAB Decision"
                            name="meta:Summary of BAB Decision"
                        >
                            <TextArea rows={6} placeholder="Enter a summary of the BAB Decision"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <hr />
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Appellant's Comments"
                            name="meta:Appellants Comments"
                        >
                            <TextArea rows={6} placeholder="Enter the appellants comments"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {formState.contactModalVisible && (
                <ContactModal onAdd={contactAdded}
                              contactType="passenger"
                              onClose={() => setFormState({...formState, contactModalVisible: false})}/>
            )}
            {formState.operatorModalVisible && (
                <OperatorModal onAdd={operatorAdded}
                               onClose={() => setFormState({...formState, operatorModalVisible: false})}/>
            )}
            {formState.typeModalVisible && (
                <CaseTypeModal onAdd={typeAdded}
                               onClose={() => setFormState({...formState, typeModalVisible: false})}/>
            )}
        </Spin>
    );
})

export default BABForm;

import React, {useContext, useEffect, useState} from 'react';
import {
    Button,
    Divider,
    Dropdown,
    Form,
    Input,
    Menu,
    Modal,
    notification,
    Select,
    Table,
    Tabs,
    Tag,
    Upload,
    List,
    Typography, Tooltip, DatePicker, Spin, Drawer
} from "antd";
import {
    DisconnectOutlined,
    DownloadOutlined,
    DownOutlined,
    QuestionCircleOutlined,
    SendOutlined,
    UploadOutlined,
    DeleteOutlined,
    ForwardOutlined,
    PaperClipOutlined
} from "@ant-design/icons";
import moment from 'moment';
import {defaultStyles, FileIcon} from 'react-file-icon';
import filesize from 'filesize';
import './index.less';
import AjaxService from "../../Services/AjaxService";
import {ProfileContext, useCan} from "../../Contexts/ProfileContext";
import { debounce } from 'lodash';

const {TabPane} = Tabs;
const {Dragger} = Upload;

function CaseTabs(props: any) {

    let isCancelled = false;

    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [fileModalState, setFileModalState] = useState({
        visible: false,
        loading: false
    });
    const [ticketResponses, setTicketResponses] = useState({
        loading: true,
        expanded: [] as string[],
        internal: [],
        external: []
    });
    const [ticketFiles, setTicketFiles] = useState({
        loading: true,
        files: []
    });
    const {Option} = Select;

    const [contactState, setContactState] = useState({
        loading: false,
        contacts: [] as any
    });

    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const {profile} = useContext(ProfileContext);
    const {canCaseAction, canCaseActionAny} = useCan(profile);

    const handleResponsesModalFileChange = (info: any) => {
        info.fileList.map((file: any) => {
            if (file.size > 52428800) {
                file.status = 'error';
                file.response = 'The file has exceeded the 50mb limit.';
            }
            return file;
        });
        setFileList(info.fileList);
    }

    const onExpand = (expanded: any, record: any) => {
        setTicketResponses({...ticketResponses, expanded: (expanded ? [record.id] : [])})
    }

    const handleFilesChange = (info: any) => {
        info.fileList.map((file: any) => {
            if (file.size > 52428800) {
                file.status = 'error';
                file.response = 'The file has exceeded the 50mb limit.';
            }
            return file;
        });
        setFileList(info.fileList);
    }

    const handleAddFiles = async () => {
        if (fileList.length) {
            setFileModalState({loading: true, visible: true});

            const doUpload = async (file: any, idx: any) => {
                (fileList[idx] as any).status = 'uploading';
                setFileList([...fileList]);

                let body: any = await processAttachment(file);
                body.ticket_id = props.case.id;
                try {
                    await AjaxService.post('case-files', body);
                    (fileList[idx] as any).status = 'done';
                } catch (e: any) {
                    (fileList[idx] as any).status = 'error';
                }
                setFileList([...fileList]);
                return (fileList[idx] as any).status === 'done';
            }

            let response: any = await Promise.all(fileList.map(doUpload));
            if (response.includes(false)) {
                notification.info({
                    message: 'Add Files',
                    description: 'Some files were not successfully uploaded.'
                });
                setFileModalState({...fileModalState, loading: false});
                setFileList(fileList.filter((f: any) => f.status !== 'done'));
            } else {
                notification.success({
                    message: 'Add Files',
                    description: `${fileList.length} file${(fileList.length === 1 ? '' : 's')} were successfully uploaded.`
                });
                setFileModalState({loading: false, visible: false});
                setFileList([]);
            }
            setTicketFiles({...ticketFiles, loading: true});
            fetchFiles();
        } else {
            setFileModalState({loading: false, visible: false});
            notification.info({
                message: 'Add Files',
                description: 'No files were uploaded'
            });
        }
    }

    const processAttachment = async (file: any) => {
        return new Promise((resolve: any, reject: any) => {
            if (file.size > 52428800) {
                reject('The file has exceeded the 50mb limit.');
            }
            let reader = new FileReader();
            reader.onload = (e: any) => {
                if (!e.target.result) {
                    reject('There was a problem reading this file.');
                }
                resolve({filename: file.originFileObj.name, content: e.target.result});
            }
            reader.onerror = (e: any) => {
                reject(e);
            }
            reader.readAsDataURL(file.originFileObj);
        });
    }

    const handleAddResponse = async (values: any) => {
        try {
            if (fileList.filter((file: any) => file.status === 'error').length) {
                return;
            }
            props.setModalState({...props.modalState, loading: true});
            if ('replyToResponse' in props.modalState && props.modalState.replyToResponse) {
                values.response_id = props.modalState.replyToResponse.id;
                values.response_type = 'external';
            }
            let body: any = {
                ticket_id: props.case.id,
                response: values.response,
                response_type: props.modalState.response_type,
                date: (props.modalState.response_type === 'internal' ? values.date : null),
                to: ('to' in values ? values.to : []),
                cc: ('cc' in values ? values.cc : []),
                bcc: ('bcc' in values ? values.bcc : []),
                subject: values.subject,
                attachments: []
            };
            if ('response_id' in values && values.response_id) {
                body.response_id = values.response_id;
            }
            body.attachments = await Promise.all(fileList.map(processAttachment));
            let response = await AjaxService.post('case-responses', body);
            if (response) {
                notification.success({
                    message: 'Add Response',
                    description: 'The response was successfully created.'
                });
                props.setModalState({...props.modalState, loading: false, visible: false});
                setTicketResponses({...ticketResponses, loading: true});
                fetchResponses();
                setFileList([]);
            } else {
                props.setModalState({...props.modalState, loading: false, visible: true});
            }
        } catch (e: any) {
            props.setModalState({...props.modalState, loading: false, visible: true});
            notification.error({
                message: 'Add Response',
                description: 'There was an issue whilst adding your response. Please try again.'
            });
        }
    }

    const handleEditResponse = async (values: any) => {
        try {
            if (fileList.filter((file: any) => file.status === 'error').length) {
                return;
            }
            props.setModalState({...props.modalState, loading: true});
            let body: any = {
                ticket_id: props.case.id,
                response_id: props.modalState.response_id,
                response: values.response,
                date: (props.modalState.response_type === 'internal' ? values.date : null),
            };
            await AjaxService.put('case-responses/' + props.modalState.response_id, body);
            notification.success({
                message: 'Edit Response',
                description: 'The response was successfully updated.'
            });
            props.setModalState({...props.modalState, loading: false, visible: false});
            setTicketResponses({...ticketResponses, loading: true});
            fetchResponses();
            setFileList([]);
        } catch (e: any) {
            props.setModalState({...props.modalState, loading: false, visible: true});
            notification.error({
                message: 'Edit Response',
                description: 'There was an issue whilst updating your response. Please try again.'
            });
        }
    }

    const responsesColumns = [{
        title: 'From',
        key: 'from',
        dataIndex: 'from',
        render: (text: any, row: any, index: any) => {
            let name = (row.from.name ? row.from.name : row.from.email);
            //email = (row.from.email ? row.from.email : row.from.name);

            return <span className="clickable_">{name}</span>;
        }
    }, {
        title: 'To',
        key: 'to',
        dataIndex: 'to',
        render: (text: any, row: any, index: any) => {
            let name = (row.to.name ? row.to.name : row.to.email);
            //email = (row.to.email ? row.to.email : row.to.name);

            return <span className="clickable_">{name}</span>;
        }
    }, {
        title: 'Subject',
        key: 'subject',
        dataIndex: 'subject'
    }, {
        title: 'Date',
        key: 'date',
        dataIndex: 'created_at',
        render: (text: any, row: any, index: any) => moment(text).format('DD/MM/YYYY HH:mm')
    }, (canCaseActionAny(props.case, ['respond', 'unlink_response']) ? {
        title: '',
        key: 'actions',
        align: 'right' as 'right',
        render: (text: any, row: any) => (<Dropdown overlay={responsesMenu(row)} placement="bottomRight">
            <Button icon={<DownOutlined/>}/>
        </Dropdown>)
    } : null)];

    const filesColumns = [{
        key: 'extension',
        dataIndex: 'extension',
        width: 50,
        render: (text: any, row: any) => {
            let styles = (text in defaultStyles ? (defaultStyles as any)[text] : undefined);
            return (<FileIcon {...styles} extension={text}/>);
        }
    }, {
        title: 'Filename',
        key: 'filename',
        dataIndex: 'name',
        render: (text: any, row: any) => <a href={row.url} target="_blank" rel="noreferrer" download={row.name}>{text}</a>
    }, {
        title: 'Filesize',
        key: 'filesize',
        dataIndex: 'filesize',
        render: (text: any) => filesize(text)
    }, {
        title: 'Date',
        key: 'date',
        dataIndex: 'created_at',
        render: (text: any) => moment(text).format('DD/MM/YYYY HH:ss')
    }, {
        title: '',
        key: 'actions',
        align: 'right' as 'right',
        render: (text: any, row: any) => <Dropdown overlay={filesMenu(row)} placement="bottomRight">
            <Button icon={<DownOutlined/>}/>
        </Dropdown>
    }];

    const attachmentsColumns = [{
        key: 'extension',
        dataIndex: 'extension',
        width: 50,
        render: (text: any, row: any) => {
            let styles = (text in defaultStyles ? (defaultStyles as any)[text] : undefined);
            return (<FileIcon {...styles} extension={text}/>);
        }
    }, {
        title: 'Filename',
        key: 'filename',
        dataIndex: 'name',
        render: (text: any, row: any) => <a href={row.url} target="_blank" rel="noreferrer" download={row.name}>{text}</a>
    }, {
        title: 'Filesize',
        key: 'filesize',
        dataIndex: 'filesize',
        render: (text: any) => filesize(text)
    }, {
        title: 'Date',
        key: 'date',
        dataIndex: 'created_at',
        render: (text: any) => moment(text).format('DD/MM/YYYY HH:ss')
    }, {
        title: '',
        key: 'actions',
        align: 'right' as 'right',
        render: (_: any, row: any) => <Dropdown overlay={attachmentsMenu(row)} placement="bottomRight">
            <Button icon={<DownOutlined/>}/>
        </Dropdown>
    }];

    const fetchResponses = async () => {
        setTicketResponses({...ticketResponses, loading: true});

        let internal: any = [],
            external: any = [],
            response: any;
        try {
            let page = 1;
            while (true) {
                response = await AjaxService.get(`case-responses`, {
                    'ticket_id': props.case.id,
                    'include': ['attachments'],
                    'page': page
                });
                if (response) {
                    response.data.forEach((row: any) => {
                        if (row.response_type === 'internal') {
                            internal.push(row);
                        } else {
                            external.push(row);
                        }
                    });
                    if (page < response.meta.last_page) {
                        page++;
                    } else {
                        break;
                    }
                }
            }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setTicketResponses({
            ...ticketResponses,
            loading: false,
            internal: (internal ? internal : ticketResponses.internal),
            external: (external ? external : ticketResponses.external),
        });
    }

    const fetchFiles = async () => {
        setTicketFiles({...ticketFiles, loading: true});

        let response;
        try {
            response = await AjaxService.get(`case-files`, {'ticket_id': props.case.id});
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setTicketFiles({
            ...ticketFiles,
            loading: false,
            files: (response ? response.data : ticketFiles.files)
        });
    }

    const unlinkResponse = async (id: any) => {
        Modal.confirm({
            title: `Unlink Response`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to unlink this response?',
            onOk() {
                // do it...
                try {
                    AjaxService.put(`case-responses/${id}/unlink`, {}).then(() => {
                        notification.success({
                            message: 'Unlink Response',
                            description: 'The response has been successfully unlinked.'
                        });
                        setTicketResponses({...ticketResponses, loading: true});
                        fetchResponses();
                    });
                } catch (e: any) {
                    notification.error({
                        message: 'Unlink Response',
                        description: 'There was an issue unlinking the response. Please try again.'
                    });
                }
            },
            onCancel() {
            },
        });
    }

    const unlinkFile = async (id: any) => {
        Modal.confirm({
            title: `Unlink File`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to unlink this file?',
            onOk() {
                // do it...
                try {
                    AjaxService.put(`case-files/${id}/unlink`, {}).then(() => {
                        notification.success({
                            message: 'Unlink File',
                            description: 'The file has been successfully unlinked'
                        });
                        setTicketFiles({...ticketFiles, loading: true});
                        fetchFiles();
                    });
                } catch (e: any) {
                    notification.error({
                        message: 'Unlink File',
                        description: 'There was an issue unlinking the file. Please try again.'
                    });
                }
            },
            onCancel() {
            },
        });
    }

    const deleteAttachment = async (row: any) => {
        Modal.confirm({
            title: `Delete Attachment`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to delete this attachment?',
            onOk() {
                // do it...
                try {
                    AjaxService.delete(`case-responses/${row.response_id}/attachments/${row.id}`, {}).then(() => {
                        notification.success({
                            message: 'Delete Attachment',
                            description: 'The attachment has been successfully deleted'
                        });
                        setTicketResponses({...ticketResponses, loading: true});
                        fetchResponses();
                        setFileList([]);
                    });
                } catch (e: any) {
                    notification.error({
                        message: 'Delete Attachment',
                        description: 'There was an issue deleting the attachment. Please try again.'
                    });
                }
            },
            onCancel() {
            },
        });
    }

    const composeToTagRenderer = (props: any) => {
        const {label, value, closable, onClose} = props;

        return (
            <Tag color={(/.*@.*\..*/.test(value) ? 'green' : 'red')} closable={closable} onClose={onClose}
                 style={{marginRight: 3}}>
                {value}
            </Tag>
        );
    }

    const addRecipient = (field: any, email: any) => {
        let val = form.getFieldValue(field) ?? [];
        if (val.indexOf(email) === -1) {
            form.resetFields([field]);
            val.push(email);
            let values: any = {};
            values[field] = val;
            form.setFieldsValue(values);
        }
    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        fetchResponses();
        fetchFiles();
        props.setModalState({...props.modalState, addResponse});
        return () => {
            isCancelled = true;
        };
    }, [props.checksum]);

    const responsesMenu = (row: any) => (
        <Menu>
            {canCaseAction(props.case, 'unlink_response') && (
                <Menu.Item key="unlink" onClick={() => unlinkResponse(row.id)}
                       icon={<DisconnectOutlined/>}>Unlink</Menu.Item>
            )}
            {canCaseAction(props.case, 'respond') && (
                <>
                    <Menu.Item key="reply" onClick={() => replyToResponse(row)} icon={<SendOutlined />}>Reply</Menu.Item>
                    <Menu.Item key="forward" onClick={() => forwardResponse(row)} icon={<ForwardOutlined />}>Forward</Menu.Item>
                </>
            )}
        </Menu>
    );

    const replyToResponse = (row: any) => {
        props.setModalState({...props.modalState, replyToResponse: row, loading: false, visible: true });

        var content: any = {};
        if (props.modalState.response_type === 'external' && props.case.contact && props.case.contact.email) {
            content.to = [props.case.contact.email];
            content.cc = [];
            content.bcc = [];
            content.response = `${profile.signature ? "\n\n" + profile.signature : ''}

[-- Add your response above this line --]

From: ${row.from?.raw}
To: ${row.to?.raw}${row.cc?.raw ? "\nCc: " + row.cc?.raw : ''}
Date: ${moment(row.created_at).format('DD/MM/YYYY HH:mm')}

${row.content}`;
        }
        content.subject = `Re: ${props.case.reference ? props.case.reference : props.case.id} - ${props.case.type.label} | Bus Users`;
        form.setFieldsValue(content);
    };

    const forwardResponse = (row: any) => {
        props.setModalState({...props.modalState, replyToResponse: row, loading: false, visible: true });

        var content: any = {};
        if (props.modalState.response_type === 'external' && props.case.contact && props.case.contact.email) {
            content.to = [props.case.contact.email];
            content.cc = [];
            content.bcc = [];
            content.response = `${profile.signature ? "\n\n" + profile.signature : ''}

[-- Add your response above this line --]

From: ${row.from?.raw}
To: ${row.to?.raw}${row.cc?.raw ? "\nCc: " + row.cc?.raw : ''}
Date: ${moment(row.created_at).format('DD/MM/YYYY HH:mm')}

${row.content}`;
        }
        content.subject = `Fw: ${props.case.reference ? props.case.reference : props.case.id} - ${props.case.type.label} | Bus Users`;
        form.setFieldsValue(content);
    };

    const addResponse = async (state: any) => {
        const lockState = await props.checkTabLock(false, props.case.id, props.case.id, props.case.type, false, false);
        if (!lockState.result) {
            return;
        }
        if (lockState.result === 'take-over') {
            let response = await AjaxService.put(`cases/lock/${props.case.id}/true`, {});
            if (response.data.status !== 'success') {
                notification.error({
                    message: 'There was an error',
                    description: response.data.message
                });
                return;
            }
        }
        props.setModalState({ ...props.modalState, ...state });
        setTimeout(() => {
            form.setFieldsValue({
                subject: `Re: ${props.case.reference ? props.case.reference : props.case.id} - ${props.case.type.label} | Bus Users`,
                response: ('response' in state && state.response.content ? state.response.content : (profile.signature ? "\n\n" + profile.signature : '')),
                date: ('response' in state ? (state.response.action_date ? moment(state.response.action_date, 'YYYY-MM-DD') : moment(state.response.created_at)) : moment())
            });
        }, 250);
    }

    const searchContacts = async (search: any) => {
        if (!search) {
            return;
        }
        setContactState((previous: any) => {
            return {
                ...previous,
                loading: true,
                contacts: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`contacts`, {filter_query: search, filter_email: true}, true);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setContactState((previous: any) => {
            return {
                ...previous,
                loading: false,
                contacts: (response ? response.data : previous.contacts)
            };
        });
    }

    const filesMenu = (row: any) => (
        <Menu>
            {canCaseAction(props.case, 'unlink_file') && (
                <Menu.Item key="unlink" onClick={() => unlinkFile(row.id)} icon={<DisconnectOutlined/>}>Unlink</Menu.Item>
            )}
            <Menu.Item key="download" icon={<DownloadOutlined/>}>
                <a href={row.url} target="_blank" rel="noreferrer" download={row.name}>Download</a>
            </Menu.Item>
        </Menu>
    );

    const attachmentsMenu = (row: any) => (
        <Menu>
            <Menu.Item key="download" icon={<DownloadOutlined/>}>
                <a href={row.url} target="_blank" rel="noreferrer" download={row.name}>Download</a>
            </Menu.Item>
            <Menu.Item key="delete" icon={<DeleteOutlined/>} onClick={() => deleteAttachment(row)}>Delete</Menu.Item>
        </Menu>
    );

    function discardCompose() {
        form.resetFields();
        props.setModalState({...props.modalState, loading: false, visible: false, response: null, response_id: null});
    }

    return (
        <>
            <Tabs defaultActiveKey="actions" className="CaseInternalTabs">
                <TabPane tab="History of Actions" key="actions">
                    <List
                        size="small"
                        bordered
                        dataSource={ticketResponses.internal}
                        renderItem={(item: any) => (
                            <List.Item>
                                <div>
                                    <div className="mb-15">
                                        <Typography.Text><Tooltip title={(item.user ? item.user.initials : 'Removed')} placement="right"><strong>{moment(item.action_date && item.action_date !== '0000-00-00' ? item.action_date : item.created_at).format('DD/MM/YY')}</strong></Tooltip></Typography.Text>
                                        &nbsp;<button type="button" onClick={() => addResponse({loading: false, visible: true, response_id: item.id, response: item, response_type: 'internal'})} className="LinkButton">[Edit]</button>
                                    </div>
                                    {item.content.replace(/([\n]+)?\[[a-f0-9]{32}\]/gi, '').split('\n').map((chunk: any, key: any) => {
                                        return (
                                            <React.Fragment key={key}>
                                                {chunk}
                                                <br/>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </List.Item>
                        )}
                    />
                    {canCaseAction(props.case, 'respond') && (
                        <Button className="mt-15"
                                onClick={() => addResponse({loading: false, visible: true, response_type: 'internal'})}>Add action</Button>
                    )}
                </TabPane>
                <TabPane tab="Responses" key="responses">
                    <Table
                        columns={responsesColumns.filter(c => c !== null) as any}
                        dataSource={ticketResponses.external}
                        loading={ticketResponses.loading}
                        rowKey="id"
                        onExpand={onExpand}
                        expandedRowKeys={ticketResponses.expanded}
                        expandable={{
                            expandedRowRender: record => <>
                                <p style={{ margin: 0 }}>
                                    <strong>From: </strong> {record.from.raw}<br />
                                    <strong>To: </strong> {record.to.raw}<br />
                                    {record.cc && record.cc.raw && (
                                        <><strong>Cc: </strong> {record.cc.raw}<br /></>
                                    )}
                                    <strong>Date:</strong> {moment(record.sent_at).format('DD/MM/YYYY HH:mm')}
                                    <br />
                                    <br />
                                    {record.content.replace(/([\n]+)?\[[a-f0-9]{32}\]/gi, '').split('\n').map((chunk: any, key: any) => {
                                        return (
                                            <React.Fragment key={key}>
                                                {chunk}
                                                <br/>
                                            </React.Fragment>
                                        );
                                    })}
                                </p>
                                {record.attachments && record.attachments.length > 0 && (
                                    <>
                                        <Divider>Attachments</Divider>
                                        <Table
                                            columns={attachmentsColumns}
                                            dataSource={record.attachments}
                                            size="small"
                                            pagination={false}
                                        >
                                        </Table>
                                    </>
                                )}
                            </>
                        }}
                    >
                    </Table>
                    {canCaseAction(props.case, 'respond') && (
                        <Button className={ticketResponses.external.length ? 'PaginationButton' : 'mt-15'}
                                onClick={() => addResponse({loading: false, visible: true, response_type: 'external'})}>Add response</Button>
                    )}
                </TabPane>
                <TabPane tab="Files" key="files">
                    <Table
                        columns={filesColumns}
                        dataSource={ticketFiles.files}
                        loading={ticketFiles.loading}
                        rowKey="id"
                    >
                    </Table>
                    {canCaseAction(props.case, 'upload') && (
                        <Button className={ticketFiles.files.length ? 'PaginationButton' : 'mt-15'}
                                onClick={() => setFileModalState({...fileModalState, visible: true})}
                                icon={<UploadOutlined/>}>Upload Files</Button>
                    )}
                </TabPane>
            </Tabs>
            {canCaseAction(props.case, 'respond') && (
            <Drawer
                    title={('response_id' in props.modalState && props.modalState.response_id ? 'Edit' : 'Add') + ' Response'}
                    className="MailDrawer"
                    visible={props.modalState.visible}
                    closable={true}
                    destroyOnClose
                    forceRender
                    onClose={() => {
                        props.setModalState({...props.modalState, loading: false, visible: false});
                        setFileList([]);
                    }}
                    footer={
                        <>
                            <div className="MailViewButtons">
                                <Button htmlType="button" onClick={discardCompose} style={{marginRight: 'auto'}}>
                                    Discard
                                </Button>
                                <Button onClick={() => form.submit()} type="primary" icon={'response_id' in props.modalState ? null : <SendOutlined/>}
                                        style={{marginLeft: '.5rem'}}>
                                    {'response_id' in props.modalState ? 'Update' : 'Send'}
                                </Button>
                            </div>
                        </>
                    }
                >
                    <Form
                        {...layout}
                        form={form}
                        preserve={false}
                        onFinish={'response_id' in props.modalState && props.modalState.response_id ? handleEditResponse : handleAddResponse}
                    >
                        {'replyToResponse' in props.modalState && props.modalState.replyToResponse !== null && (
                            <p><strong>In reply to:</strong> {props.modalState.replyToResponse.subject}</p>
                        )}
                        {props.modalState.response_type === 'external' && (
                            <>
                                <Form.Item
                                    label="Subject"
                                    name="subject"
                                    rules={[{required: true, message: 'Please enter a subject'}]}
                                >
                                    <Input />
                                </Form.Item>
                                <div className="ant-form-item-label" style={{display: 'flex'}}>
                                    <label className="ant-form-item-required" style={{marginRight: 'auto'}}>To</label>
                                    {props.case?.operator && 'contact' in props.case.operator && props.case.operator.contact && props.case.operator.contact.email.length > 0 && (
                                        <Button onClick={(e) => addRecipient('to', props.case.operator.contact.email)} size="small" style={{marginLeft: '1rem'}}>Add Operator</Button>
                                    )}
                                </div>
                                <Form.Item
                                    name="to"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        type: 'array',
                                        message: 'Please enter at least one valid email address!'
                                    }]}
                                >
                                    <Select
                                        mode="tags"
                                        placeholder="To"
                                        showSearch
                                        onSearch={debounce(searchContacts, 500)}
                                        filterOption={false}
                                        notFoundContent={contactState.loading ? <Spin size="small"/> : null}
                                        loading={contactState.loading}
                                        tagRender={composeToTagRenderer}
                                    >
                                        {contactState.contacts.map((contact: any) => {
                                            return (
                                                <Option key={contact.id.toString()} value={contact.email}>
                                                    <span>{contact.first_name} {contact.last_name}</span>
                                                    <small style={{display: 'block'}}>{contact.email}</small>
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <div className="ant-form-item-label" style={{display: 'flex'}}>
                                    <label className="">Cc</label>
                                    {props.case?.operator && 'contact' in props.case.operator && props.case.operator.contact && props.case.operator.contact.email.length > 0 && (
                                        <Button onClick={(e) => addRecipient('cc', props.case.operator.contact.email)} size="small" style={{marginLeft: 'auto'}}>Add Operator</Button>
                                    )}
                                </div>
                                <Form.Item
                                    name="cc"
                                    rules={[{
                                        type: 'array',
                                        message: 'Please enter at least one valid email address!'
                                    }]}
                                >
                                    <Select
                                        mode="tags"
                                        placeholder="Cc"
                                        showSearch
                                        onSearch={debounce(searchContacts, 500)}
                                        filterOption={false}
                                        notFoundContent={contactState.loading ? <Spin size="small"/> : null}
                                        loading={contactState.loading}
                                        tagRender={composeToTagRenderer}
                                    >
                                        {contactState.contacts.map((contact: any) => {
                                            return (
                                                <Option key={contact.id.toString()} value={contact.email}>
                                                    <span>{contact.first_name} {contact.last_name}</span>
                                                    <small style={{display: 'block'}}>{contact.email}</small>
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <div className="ant-form-item-label" style={{display: 'flex'}}>
                                    <label className="">Bcc</label>
                                    {props.case?.operator && 'contact' in props.case.operator && props.case.operator.contact && props.case.operator.contact.email.length > 0 && (
                                        <Button onClick={(e) => addRecipient('bcc', props.case.operator.contact.email)} size="small" style={{marginLeft: 'auto'}}>Add Operator</Button>
                                    )}
                                </div>
                                <Form.Item
                                    name="bcc"
                                    rules={[{
                                        type: 'array',
                                        message: 'Please enter at least one valid email address!'
                                    }]}
                                >
                                    <Select
                                        mode="tags"
                                        placeholder="Bcc"
                                        showSearch
                                        onSearch={debounce(searchContacts, 500)}
                                        filterOption={false}
                                        notFoundContent={contactState.loading ? <Spin size="small"/> : null}
                                        loading={contactState.loading}
                                        tagRender={composeToTagRenderer}
                                    >
                                        {contactState.contacts.map((contact: any) => {
                                            return (
                                                <Option key={contact.id.toString()} value={contact.email}>
                                                    <span>{contact.first_name} {contact.last_name}</span>
                                                    <small style={{display: 'block'}}>{contact.email}</small>
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </>
                        )}
                        <Form.Item
                            name="response"
                            label={props.modalState.response_type === 'external' ? 'Response' : 'Action'}
                            rules={[{required: true, message: 'Please enter a response'}]}
                        >
                            <Input.TextArea rows={8} placeholder="Response"/>
                        </Form.Item>
                        {false && !('replyToResponse' in props.modalState && props.modalState.replyToResponse !== null) && (
                            <Form.Item
                                label="Notify User?"
                                name="response_type"
                                rules={[{required: true, message: 'Please specify whether to notify the user'}]}
                            >
                                <Select placeholder="Notify User?">
                                    <Select.Option key="internal" value="internal">No, internal use only</Select.Option>
                                    <Select.Option key="external" value="external">Yes, send them an email</Select.Option>
                                </Select>
                            </Form.Item>
                        )}
                        {props.modalState.response_type === 'internal' && (
                            <Form.Item
                                label="Date"
                                name="date"
                            >
                                <DatePicker defaultValue={moment()} placeholder="Date" />
                            </Form.Item>
                        )}
                        {props.modalState.response_type === 'external' && (
                            <Form.Item
                                label="Attachments"
                                name="attachments"
                            >
                                <Upload beforeUpload={() => false} onChange={handleResponsesModalFileChange} multiple
                                        fileList={fileList}>
                                    <Button icon={<UploadOutlined/>}>Add attachments</Button>
                                </Upload>
                            </Form.Item>
                        )}
                    </Form>
                </Drawer>
            )}

            {false && canCaseAction(props.case, 'respond') && (
                <Modal
                    title={props.modalState.response_type === 'external' ? 'Add response' : 'Add action'}
                    destroyOnClose
                    visible={props.modalState.visible}
                    onOk={() => form.submit()}
                    onCancel={() => {
                        props.setModalState({...props.modalState, loading: false, visible: false});
                        setFileList([]);
                    }}
                    confirmLoading={props.modalState.loading}
                    okText={props.modalState.response_type === 'external' ? 'Add response' : 'Add action'}
                >
                    <Form
                        {...layout}
                        form={form}
                        preserve={false}
                        onFinish={handleAddResponse}
                        initialValues={props.modalState.response_type === 'external' && props.case.contact && props.case.contact.email ? {to: [props.case.contact.email], cc: [], bcc: []} : {}}
                    >
                        {'replyToResponse' in props.modalState && props.modalState.replyToResponse !== null && (
                            <p><strong>In reply to:</strong> {props.modalState.replyToResponse.subject}</p>
                        )}
                        {props.modalState.response_type === 'external' && (
                            <>
                                <div className="ant-form-item-label" style={{display: 'flex'}}>
                                    <label className="">To</label>
                                    {props.case?.operator && 'contact' in props.case.operator && props.case.operator.contact && props.case.operator.contact.email.length > 0 && (
                                        <Button onClick={(e) => addRecipient('to', props.case.operator.contact.email)} size="small" style={{marginLeft: 'auto'}}>Add Operator</Button>
                                    )}
                                </div>
                                <Form.Item
                                    name="to"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        type: 'array',
                                        message: 'Please enter at least one valid email address!'
                                    }]}
                                >
                                    <Select
                                        mode="tags"
                                        placeholder="To"
                                        showSearch
                                        onSearch={debounce(searchContacts, 500)}
                                        filterOption={false}
                                        notFoundContent={contactState.loading ? <Spin size="small"/> : null}
                                        loading={contactState.loading}
                                        tagRender={composeToTagRenderer}
                                    >
                                        {contactState.contacts.map((contact: any) => {
                                            return (
                                                <Option key={contact.id.toString()} value={contact.email}>
                                                    <span>{contact.first_name} {contact.last_name}</span>
                                                    <small style={{display: 'block'}}>{contact.email}</small>
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <div className="ant-form-item-label" style={{display: 'flex'}}>
                                    <label className="">Cc</label>
                                    {props.case?.operator && 'contact' in props.case.operator && props.case.operator.contact && props.case.operator.contact.email.length > 0 && (
                                        <Button onClick={(e) => addRecipient('cc', props.case.operator.contact.email)} size="small" style={{marginLeft: 'auto'}}>Add Operator</Button>
                                    )}
                                </div>
                                <Form.Item
                                    name="cc"
                                    rules={[{
                                        type: 'array',
                                        message: 'Please enter at least one valid email address!'
                                    }]}
                                >
                                    <Select
                                        mode="tags"
                                        placeholder="Cc"
                                        showSearch
                                        onSearch={debounce(searchContacts, 500)}
                                        filterOption={false}
                                        notFoundContent={contactState.loading ? <Spin size="small"/> : null}
                                        loading={contactState.loading}
                                        tagRender={composeToTagRenderer}
                                    >
                                        {contactState.contacts.map((contact: any) => {
                                            return (
                                                <Option key={contact.id.toString()} value={contact.email}>
                                                    <span>{contact.first_name} {contact.last_name}</span>
                                                    <small style={{display: 'block'}}>{contact.email}</small>
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <div className="ant-form-item-label" style={{display: 'flex'}}>
                                    <label className="">Bcc</label>
                                    {props.case?.operator && 'contact' in props.case.operator && props.case.operator.contact && props.case.operator.contact.email.length > 0 && (
                                        <Button onClick={(e) => addRecipient('bcc', props.case.operator.contact.email)} size="small" style={{marginLeft: 'auto'}}>Add Operator</Button>
                                    )}
                                </div>
                                <Form.Item
                                    name="bcc"
                                    rules={[{
                                        type: 'array',
                                        message: 'Please enter at least one valid email address!'
                                    }]}
                                >
                                    <Select
                                        mode="tags"
                                        placeholder="Bcc"
                                        showSearch
                                        onSearch={debounce(searchContacts, 500)}
                                        filterOption={false}
                                        notFoundContent={contactState.loading ? <Spin size="small"/> : null}
                                        loading={contactState.loading}
                                        tagRender={composeToTagRenderer}
                                    >
                                        {contactState.contacts.map((contact: any) => {
                                            return (
                                                <Option key={contact.id.toString()} value={contact.email}>
                                                    <span>{contact.first_name} {contact.last_name}</span>
                                                    <small style={{display: 'block'}}>{contact.email}</small>
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </>
                        )}
                        <Form.Item
                            name="response"
                            label={props.modalState.response_type === 'external' ? 'Response' : 'Action'}
                            rules={[{required: true, message: 'Please enter a response'}]}
                        >
                            <Input.TextArea rows={8} placeholder="Response"/>
                        </Form.Item>
                        {false && !('replyToResponse' in props.modalState && props.modalState.replyToResponse !== null) && (
                            <Form.Item
                                label="Notify User?"
                                name="response_type"
                                rules={[{required: true, message: 'Please specify whether to notify the user'}]}
                            >
                                <Select placeholder="Notify User?">
                                    <Select.Option key="internal" value="internal">No, internal use only</Select.Option>
                                    <Select.Option key="external" value="external">Yes, send them an email</Select.Option>
                                </Select>
                            </Form.Item>
                        )}
                        {props.modalState.response_type === 'internal' && (
                            <Form.Item
                                label="Date"
                                name="date"
                            >
                                <DatePicker defaultValue={moment()} placeholder="Date" />
                            </Form.Item>
                        )}
                        {props.modalState.response_type === 'external' && (
                            <Form.Item
                                label="Attachments"
                                name="attachments"
                            >
                                <Upload beforeUpload={() => false} onChange={handleResponsesModalFileChange} multiple
                                        fileList={fileList}>
                                    <Button icon={<UploadOutlined/>}>Add attachments</Button>
                                </Upload>
                            </Form.Item>
                        )}
                    </Form>
                </Modal>
            )}

            {canCaseAction(props.case, 'upload') && (
                <Modal
                    title="Upload Files"
                    destroyOnClose
                    visible={fileModalState.visible}
                    onCancel={() => setFileModalState({loading: false, visible: false})}
                    footer={[
                        <Button key="close" onClick={() => setFileModalState({loading: false, visible: false})}>
                            Close
                        </Button>,
                        <Button type="primary" loading={fileModalState.loading} key="save" onClick={handleAddFiles}>
                            Save
                        </Button>
                    ]}
                >
                    <Dragger
                        multiple
                        onChange={handleFilesChange}
                        fileList={fileList}
                        beforeUpload={() => false}
                    >
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined/>
                        </p>
                        <p className="ant-upload-text">Click or drag files to this area to be uploaded.</p>
                    </Dragger>
                </Modal>
            )}
        </>
    );
}

export default CaseTabs;
import React, {useEffect, useImperativeHandle, useState} from 'react';
import {Button, Cascader, Col, DatePicker, Form, Input, notification, Row, Select, Spin, Tooltip} from 'antd';
import {PlusOutlined, QuestionCircleOutlined, EditOutlined} from '@ant-design/icons';
import ContactModal from '../../../../Components/ContactModal';
import LocationModal from '../../../../Components/LocationModal';
import OperatorModal from '../../../../Components/OperatorModal';
import CaseTypeModal from '../../../../Components/CaseTypeModal';
import './index.less';
import AjaxService from "../../../../Services/AjaxService";
import moment from 'moment';
import { debounce } from 'lodash';

const {TextArea} = Input;

const ComplaintForm = React.forwardRef((props: any, ref: any) => {

    let isCancelled: any = false;

    const [form] = Form.useForm();
    const {Option} = Select;

    const [formState, setFormState] = useState({
        ticket: false as any,
        contact: false as any,
        ticketLoading: (props.action === 'edit'),
        locations: [] as any,
        locationSearch: '' as any,
        loadingLocations: true,
        contacts: [] as any,
        contactSearch: '' as any,
        locationModalVisible: false as any,
        loadingContacts: false,
        contactModalVisible: false,
        selectedContact: false as any,
        operators: [] as any,
        operatorSearch: '' as any,
        loadingOperators: true,
        operatorModalVisible: false,
        caseStatuses: [] as any,
        loadingCaseStatuses: true,
        caseStatusSearch: '',
        types: [] as any,
        loadingTypes: true,
        typeModalVisible: false,
        users: [] as any,
        loadingUsers: true,
        countries: [] as any,
        loadingCountries: true,
        countrySearch: '',
        allCases: false as any
    });

    const contactAdded = (contact: any) => {
        formState.contacts.push(contact);
        setFormState({
            ...formState,
            contacts: [
                ...formState.contacts
            ],
            contactModalVisible: false
        });
        form.setFieldsValue({
            contact_id: contact.id.toString()
        });
        notification.success({
            message: 'Add Contact',
            description: `The contact was successfully created.`
        });
    }

    const contactEdited = (contact: any) => {
        let idx = formState.contacts.reduce((acc: any, _contact: any, index: any) => { if (_contact.id.toString() === contact.id.toString()) { acc = index; } return acc; }, -1);
        if (idx > -1) {
            formState.contacts[idx] = contact;
        }
        setFormState({
            ...formState,
            contacts: [
                ...formState.contacts
            ],
            contactModalVisible: false
        });
        form.setFieldsValue({
            contact_id: contact.id.toString()
        });
        notification.success({
            message: 'Add Contact',
            description: `The contact was successfully created.`
        });
    }

    const operatorAdded = (operator: any) => {
        formState.operators.push({
            label: operator.title,
            value: operator.id.toString()
        });
        setFormState({
            ...formState,
            operators: [
                ...formState.operators
            ],
            operatorModalVisible: false
        });
        form.setFieldsValue({
            operator_id: operator.id.toString()
        });
        notification.success({
            message: 'Add Operator',
            description: `The operator was successfully created..`
        });
    }

    const typeAdded = (type: any) => {
        let selected_types = form.getFieldValue('meta:Types');
        Object.keys(type).forEach((code: any) => {
            formState.types.push({
                label: code + '. ' + type[code],
                value: code
            });
            selected_types.push(code);
        });

        setFormState({
            ...formState,
            types: [
                ...formState.types
            ],
            typeModalVisible: false
        });
        form.setFieldsValue({
            'meta:Types': [...selected_types]
        });
        notification.success({
            message: 'Add Type',
            description: `The type was successfully created.`
        });
    }

    const locationAdded = (location: any) => {
        formState.locations.forEach((tmp: any, idx: any) => {
            if (tmp.value == location.region_id) {
                formState.locations[idx].children.push({
                    label: location.title,
                    value: Number.parseInt(location.id),
                    children: []
                });
            }
        });
        setFormState({
            ...formState,
            loadingLocations: true,
            locations: [
                ...formState.locations
            ],
        });
        form.setFieldsValue({
            location_id: (location ? [location.region_id, location.id] : [])
        });
        fetchLocationTree((location ? [location.region_id, location.id] : []));
        notification.success({
            message: 'Add Location',
            description: `The location was successfully created.`
        });
    }

    const searchLocations = async(search: any) => {
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingLocations: true,
                locationSearch: search,
                locations: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`locations`, {filter_query: search}, true);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingLocations: false,
                locations: (response ? response.data : previous.locations)
            };
        });
    }

    const fetchLocationTree = async (selected_id: any = null) => {
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingLocations: true,
                locations: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`locations/tree`, {});
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingLocations: false,
                locations: (response ? response : previous.locations)
            };
        });
        if (selected_id) {
            form.setFieldsValue({
                location_id: selected_id
            });
        }
    }

    const fetchOperators = async () => {
        try {
            let response = await AjaxService.get(`operators/`, {
                include_deleted: true
            }, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingOperators: false,
                    operators: response.data.map((operator: any) => {
                        return {
                            label: operator.title + (operator.deleted_at ? ' (Inactive)' : ''),
                            value: operator.id.toString()
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchCaseStatuses = async () => {
        try {
            let response = await AjaxService.get(`case-statuses/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingCaseStatuses: false,
                    caseStatuses: response.data.map((status: any) => {
                        return {
                            label: status.title,
                            value: status.id.toString()
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchCountries = async () => {
        try {
            let response = await AjaxService.get(`cases/meta/`, {
                filter_case_type: 'complaint',
                filter_key: 'Country'
            }, true);
            setFormState((previous: any) => {
                let countries: any = response.data.reduce((acc: any, country: any) => {
                    if (acc.indexOf(country) === -1) {
                        acc.push(country);
                    }
                    return acc;
                }, [
                    'England 1st Line', 
                    'Scotland 1st Line', 
                    'Wales 1st Line', 
                    'England 2nd Stage', 
                    'Scotland 2nd Stage', 
                    'Wales 2nd Stage'
                ]).map(function(country: any) {
                    return {
                        value: country,
                        label: country
                    };
                });
                return {
                    ...previous,
                    loadingCountries: false,
                    countries
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchTypes = async () => {
        try {
            let response = await AjaxService.get(`case-types`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingTypes: false,
                    types: Object.keys(response.data).map((code: any) => {
                        return {
                            label: code + '. ' + response.data[code],
                            value: code
                        }
                    }).sort((a, b) => a.value.localeCompare(b.value, undefined, { numeric: true, sensitivity: 'base' }))
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const searchContacts = async (search: any) => {
        if (!search) {
            return;
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingContacts: true,
                contactSearch: search,
                contacts: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`contacts`, {limit: 100, filter_query: search}, true);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingContacts: false,
                contacts: (response ? response.data : previous.contacts)
            };
        });
    }

    const searchOperators = async (search: any) => {
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingOperators: true,
                operatorSearch: search,
                operators: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`operators`, {filter_query: search}, true);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingOperators: false,
                operators: (response ? response.data : previous.operators)
            };
        });
    }

    const fetchUsers = async () => {
        try {
            let response = await AjaxService.get(`users/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingUsers: false,
                    users: response.data.map((user: any) => {
                        return {
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id.toString()
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchCase = async () => {
        let response: any;
        try {
            console.log('Complaint/Form: fetching data...');
            response = await AjaxService.get(`cases/${props.id}`, {'include': ['meta'], 'initial_response': true});
            let crossBorder = Number.parseInt(response.data.meta['Cross Border']);
            if (isNaN(crossBorder)) {
                crossBorder = 0;
            }
            form.setFieldsValue({
                contact: response.data.contact,
                contact_id: response.data.contact?.id.toString(),
                location_id: (response.data.location ? [response.data.location.region_id, response.data.location.id] : []),
                location: response.data.location,
                'meta:Name': response.data.meta['Name'],
                'meta:Country': response.data.meta['Country'],
                'meta:Cross Border': crossBorder,
                'meta:Non-Acceptance of Complaint Code': ('Non-Acceptance of Complaint Code' in response.data.meta ? response.data.meta['Non-Acceptance of Complaint Code'] : ''),
                'meta:Types': (response.data.meta['Types'] ? Object.keys(response.data.meta['Types']) : []),
                operator_id: response.data.operator?.id.toString(),
                operator: response.data.operator,
                status_id: response.data.status?.id.toString(),
                assigned_to: response.data.assigned_to?.id.toString(),
                message: response.data.initial_response,
                closure_reason: response.data.closure_reason,
                closed_at: (response.data.closed_at ? moment(response.data.closed_at, 'YYYY-MM-DD HH:mm:ss') : ''),
                accepted_at: (response.data.accepted_at ? moment(response.data.accepted_at, 'YYYY-MM-DD HH:mm:ss') : ''),
                completed_at: (response.data.completed_at ? moment(response.data.completed_at, 'YYYY-MM-DD HH:mm:ss') : '')
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                ticket: (response ? response.data : previous.ticket),
                ticketLoading: false
            }
        });
    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        form.resetFields();
        if (props.action === 'edit') {
            fetchCase();
        } else if (props.formState.extra !== false && props.formState.extra !== undefined && Object.keys(props.formState.extra).length > 0) {
            form.setFieldsValue({
                contact_id: props.formState.extra?.contact?.id.toString(),
                contact: props.formState.extra?.contact,
                'meta:Name': props.formState.extra?.meta['Name'],
                'meta:Cross Border': props.formState.extra?.meta['Cross Border'],
                location_id: (props.formState.extra.location ? [props.formState.extra.location.region_id, props.formState.extra.location.id] : null),
                // location_id: props.formState.extra?.location?.id.toString(),
                location: props.formState.extra?.location,
                operator_id: props.formState.extra.operator?.id.toString(),
                operator: props.formState.extra.operator,
                assigned_to: props.formState.extra.assigned_to?.id.toString(),
                message: props.formState.extra.initial_response,
                closure_reason: props.formState.extra.closure_reason,
                closed_at: (props.formState.extra.closed_at ? moment(props.formState.extra.closed_at, 'YYYY-MM-DD HH:mm:ss') : ''),
                accepted_at: (props.formState.extra.accepted_at ? moment(props.formState.extra.accepted_at, 'YYYY-MM-DD HH:mm:ss') : ''),
                completed_at: (props.formState.extra.completed_at ? moment(props.formState.extra.completed_at, 'YYYY-MM-DD HH:mm:ss') : '')
            });
        }
        fetchLocationTree();
        fetchOperators();
        fetchCaseStatuses();
        fetchUsers();
        fetchCountries();
        fetchTypes();
        return () => {
            isCancelled = true;
        };
    }, []);

    const filterSelect = (input: any, option: any) => {
        let value = ('children' in option ? option.children : option.label);
        if (Array.isArray(value)) {
            value = value.join('');
        }
        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const onSubmit = async (values: any) => {

        try {
            let body = JSON.parse(JSON.stringify(values));
            if (body.location_id) {
                body.location_id = body.location_id.pop();
            }
            body.type = 'complaint';

            let response;
            if (props.action === 'edit') {
                response = await AjaxService.put(`cases/${props.id}`, body);
            } else {
                if (props.formState.extra && Object.keys(props.formState.extra).length > 0) {
                    body['meta:Enquiry'] = props.formState.extra.id;
                }
                response = await AjaxService.post('cases', body);
            }
            props.onAdd(response.data.id, (response.data.reference ? response.data.reference : response.data.id), response.data.type.value, props.action);
        } catch (e: any) {
            notification.error({
                message: (props.action === 'edit' ? 'Edit' : 'Add') + ' Complaint',
                description: 'There was an issue ' + (props.action === 'edit' ? 'updat' : 'creat') + 'ing the case. Please try again.'
            });
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loading: false
                };
            });
        }

    }

    const getSelectedContact = () => {
        let contact_id: any = form.getFieldValue('contact_id'),
            contact: any = false;
        if (/[0-9]+/.test(contact_id)) {
            if (props.action === 'edit' && formState.ticket?.contact) {
                contact = formState.ticket?.contact;
            } else if (props.action === 'add' && props.formState.extra?.contact) {
                contact = props.formState.extra?.contact;
            } else if (formState.contacts.length > 0) {
                contact = formState.contacts.reduce((acc: any, contact: any) => {
                    if (contact.id.toString() === contact_id.toString()) {
                        acc = contact;
                    }
                    return acc;
                });
            }
        }
        return contact;
    }

    const syncComplainantContact = (e: any) => {
        if (!form.getFieldValue('contact')) {
            form.setFieldsValue({ contact: e.target.value });
        }
    }

    useImperativeHandle(ref, () => ({
        submitForm() {
            form.submit();
        }
    }));

    return (
        <Spin spinning={formState.ticketLoading}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onSubmit}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        {/* <Form.Item
                          label="Complainant"
                          name="meta:Name"
                          rules={[{required: false, message: 'Please enter the appellant!'}]}
                        >
                            <Input placeholder="Complainant" 
                                onBlur={syncComplainantContact} />
                        </Form.Item> */}
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-required">Complainant</label>
                        </div>
                        <Input.Group compact>
                            <>
                                <Form.Item
                                    name="contact_id"
                                    rules={[{required: true, message: 'Please select a contact or create a new one!'}]}
                                    style={{width: "calc(100% - " + (/[0-9]+/.test(form.getFieldValue('contact_id')) ? 64 : 32) + "px)"}}
                                >
                                    <Select placeholder="Search for a contact" showSearch={true} loading={formState.loadingContacts} onSearch={debounce(searchContacts, 300)} filterOption={false} onSelect={() => setFormState({...formState, contactSearch: ''})}>
                                        {formState.contactSearch === '' && formState.contacts.length === 0 && props.action === 'edit' && formState.ticket?.contact && (
                                            <Option key={formState.ticket.contact.id.toString()}
                                                value={formState.ticket.contact.id.toString()}>
                                                <div className={formState.ticket.contact.has_open_cases ? 'has-open-cases' : ''}>
                                                    {formState.ticket.contact.first_name} {formState.ticket.contact.last_name}
                                                    <small style={{ display: 'block' }}>{formState.ticket.contact.email}</small>
                                                </div>
                                            </Option>
                                        )}
                                        {formState.contactSearch === '' && formState.contacts.length === 0 && props.action === 'add' && props.formState.extra?.contact && (
                                            <Option key={props.formState.extra.contact.id.toString()}
                                                value={props.formState.extra.contact.id.toString()}>
                                                <div className={props.formState.extra.contact.has_open_cases ? 'has-open-cases' : ''}>
                                                    {props.formState.extra.contact.first_name} {props.formState.extra.contact.last_name}
                                                    <small style={{ display: 'block' }}>{props.formState.extra.contact.email}</small>
                                                </div>
                                            </Option>
                                        )}
                                        {formState.contacts.map((contact: any) => {
                                            return (
                                                <Option key={contact.id.toString()} value={contact.id.toString()}>
                                                    <div className={contact.has_open_cases ? 'has-open-cases' : ''}>
                                                        {contact.first_name} {contact.last_name}
                                                        <small style={{ display: 'block' }}>{contact.email}</small>
                                                    </div>
                                                </Option>
                                            );
                                        })}
                                        {formState.contactSearch !== '' && (
                                            <Option key={'search'} value={formState.contactSearch}>
                                                Quick Add &quot;{formState.contactSearch}&quot;
                                            </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </>
                            {/[0-9]+/.test(form.getFieldValue('contact_id')) && (
                                <Tooltip title="Edit Contact">
                                    <Button icon={<EditOutlined/>}
                                            onClick={() => setFormState({...formState, contact: getSelectedContact(), contactModalVisible: true})}/>
                                </Tooltip>
                            )}
                            <Tooltip title="Add Contact">
                                <Button icon={<PlusOutlined/>}
                                        onClick={() => setFormState({...formState, contact: false, contactModalVisible: true})}/>
                            </Tooltip>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                        {/* <Form.Item
                          label="Location"
                          name="location"
                          rules={[{required: false, message: 'Please enter the appellant!'}]}
                        >
                            <Input placeholder="Location"/>
                        </Form.Item> */}
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-requiredx">Location</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="location_id"
                                rules={[{required: false, message: 'Please select a location or create a new one!'}]}
                                style={{width: "calc(100% - 32px)"}}
                            >
                                <Cascader
                                    disabled={formState.loadingLocations}
                                    options={formState.locations}
                                    style={{width: '100%'}}
                                    showSearch={{
                                        filter: (inputValue, path) => {
                                            return path.some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
                                        }
                                    }}
                                    placeholder="Search for a location"
                                />
                                {/* <Select showSearch={true} onSearch={debounce(searchLocations, 300)} filterOption={false} onSelect={() => setFormState({...formState, locationSearch: ''})}>
                                    {formState.locationSearch === '' && formState.locations.length === 0 && props.action === 'edit' && formState.ticket?.location && (
                                        <Option key={formState.ticket.location.id.toString()}
                                                value={formState.ticket.location.id.toString()}>
                                            {(formState.ticket.location.region ? `${formState.ticket.location.region} > ` : '')} {formState.ticket.location.title}
                                        </Option>
                                    )}
                                    {props.action === 'add' && formState.locationSearch === '' && formState.locations.length === 0 && props.formState.extra?.location && (
                                        <Option key={props.formState.extra.location.id.toString()}
                                                value={props.formState.extra.location.id.toString()}>
                                            {(props.formState.extra.location.region ? `${props.formState.extra.location.region} > ` : '')} {props.formState.extra.location.title}
                                        </Option>
                                    )}
                                    {formState.locations.map((location: any) => {
                                        return (
                                            <Option key={location.id.toString()} value={location.id.toString()}>
                                                {(location.region ? `${location.region} > ` : '')} {location.title}
                                            </Option>
                                        );
                                    })}
                                    {formState.locationSearch !== '' && (
                                        <Option key={'search'} value={formState.locationSearch}>
                                            Quick Add &quot;{formState.locationSearch}&quot;
                                        </Option>
                                    )}
                                </Select> */}
                            </Form.Item>
                            <Tooltip title="Add Location">
                                <Button disabled={formState.loadingLocations} icon={<PlusOutlined/>}
                                        onClick={() => setFormState({...formState, locationModalVisible: true})}/>
                            </Tooltip>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Country"
                            name="meta:Country"
                            rules={[{required: false, message: 'Please select a country or create a new one!'}]}
                        >
                            <Select
                                style={{width: '100%'}}
                                showSearch
                                onSearch={(value: any) => setFormState({...formState, countrySearch: value})}
                                filterOption={filterSelect}
                                loading={formState.loadingCountries}
                                onChange={(value: any, option: any) => {
                                    if (option.key === 'custom') {
                                        setFormState({
                                            ...formState,
                                            countrySearch: '',
                                            countries: [
                                                ...formState.countries, {
                                                    value: value,
                                                    label: value
                                                }
                                            ]
                                        });
                                        form.setFieldsValue({
                                            'meta:Country': value
                                        });
                                    }
                                }}
                                placeholder="Search for a country"
                            >
                                {formState.countries && formState.countries.map((country: any) => {
                                    return <Select.Option key={country.value}
                                                          value={country.value}>{country.label}</Select.Option>
                                })}
                                {formState.countrySearch && (
                                    <Select.Option key="custom"
                                                   value={formState.countrySearch}>Add &ldquo;{formState.countrySearch}&rdquo;</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Cross Border"
                            name="meta:Cross Border"
                            initialValue={0}
                            rules={[{required: false, message: 'Please specify if this journey was across borders!'}]}
                        >
                            <Select
                                style={{width: '100%'}}
                            >
                                <Select.Option key={0} value={0}>No</Select.Option>
                                <Select.Option key={1} value={1}>Yes</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Non-Acceptance of Complaint Code"
                            name="meta:Non-Acceptance of Complaint Code"
                        >
                            <Select
                                style={{width: '100%'}}
                                allowClear
                                placeholder="Select a Non-Acceptance of Complaint Code"
                            >
                                {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'J'].map((letter: any) => {
                                    return <Select.Option key={letter} value={letter}>{letter}</Select.Option>;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-requiredx">Types</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="meta:Types"
                                rules={[{required: false, message: 'Please select a type or create a new one!'}]}
                                style={{width: "calc(100% - 32px)"}}
                            >
                                <Select
                                    style={{width: '100%'}}
                                    showSearch
                                    mode="multiple"
                                    maxTagCount={4}
                                    filterOption={filterSelect}
                                    loading={formState.loadingTypes}
                                    placeholder="Search for a type"
                                    optionLabelProp="code"
                                    options={formState.types}
                                />
                            </Form.Item>
                            <Tooltip title="Add Type">
                                <Button icon={<PlusOutlined/>}
                                        onClick={() => setFormState({...formState, typeModalVisible: true})}/>
                            </Tooltip>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                        {/* <Form.Item
                          label="Operator"
                          name="operator"
                          rules={[{required: false, message: 'Please enter the appellant!'}]}
                        >
                            <Input placeholder="Operator"/>
                        </Form.Item> */}
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-requiredx">Operator</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="operator_id"
                                rules={[{required: false, message: 'Please select a operator or create a new one!'}]}
                                style={{width: "calc(100% - 32px)"}}
                            >
                                <Select
                                    placeholder="Search for a operator"
                                    style={{width: '100%'}}
                                    showSearch
                                    filterOption={filterSelect}
                                    loading={formState.loadingOperators}
                                    options={formState.operators}
                                />
                                {/* <Select showSearch={true} onSearch={debounce(searchOperators, 300)} filterOption={false} onSelect={() => setFormState({...formState, operatorSearch: ''})}>
                                    {formState.operatorSearch === '' && formState.operators.length === 0 && props.action === 'edit' && formState.ticket?.operator && (
                                        <Option key={formState.ticket.operator.id.toString()}
                                                value={formState.ticket.operator.id.toString()}>
                                            {formState.ticket.operator.title}
                                        </Option>
                                    )}
                                    {props.action === 'add' && formState.locationSearch === '' && formState.locations.length === 0 && props.formState.extra?.operator && (
                                        <Option key={props.formState.extra.operator.id.toString()}
                                                value={props.formState.extra.operator.id.toString()}>
                                            {props.formState.extra.operator.title}
                                        </Option>
                                    )}
                                    {formState.operators.map((operator: any) => {
                                        return (
                                            <Option key={operator.id.toString()} value={operator.id.toString()}>
                                                {operator.title}
                                            </Option>
                                        );
                                    })}
                                    {formState.operatorSearch !== '' && (
                                        <Option key={'search'} value={formState.operatorSearch}>
                                            Quick Add &quot;{formState.operatorSearch}&quot;
                                        </Option>
                                    )}
                                </Select> */}
                            </Form.Item>
                            <Tooltip title="Add Operator">
                                <Button icon={<PlusOutlined/>}
                                        onClick={() => setFormState({...formState, operatorModalVisible: true})}/>
                            </Tooltip>
                        </Input.Group>
                    </Col>
                    {/* <Col span={12}>
                        <Form.Item
                            label="Contact"
                            name="contact"
                        >
                            <Input placeholder="Contact"/>
                        </Form.Item>
                    </Col> */}
                    <Col span={12}>
                        <Form.Item
                            label="Status"
                            name="status_id"
                            initialValue={'1'}
                            rules={[{required: true, message: 'Please select a status or create a new one!'}]}
                        >
                            <Select
                                style={{width: '100%'}}
                                showSearch
                                onSearch={(value: any) => setFormState({...formState, caseStatusSearch: value})}
                                filterOption={filterSelect}
                                loading={formState.loadingCaseStatuses}
                                onChange={(value: any, option: any) => {
                                    if (option.key === 'custom') {
                                        setFormState({
                                            ...formState,
                                            caseStatusSearch: '',
                                            caseStatuses: [
                                                ...formState.caseStatuses, {
                                                    value: value,
                                                    label: value
                                                }
                                            ]
                                        });
                                        form.setFieldsValue({
                                            status_id: value
                                        });
                                    }
                                }}
                                placeholder="Search for a status"
                            >
                                {formState.caseStatuses && formState.caseStatuses.map((status: any) => {
                                    return <Select.Option key={status.value}
                                                          value={status.value}>{status.label}</Select.Option>
                                })}
                                {formState.caseStatusSearch && (
                                    <Select.Option key="custom"
                                                   value={formState.caseStatusSearch}>Add &ldquo;{formState.caseStatusSearch}&rdquo;</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Assigned To"
                            name="assigned_to"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Search for a user"
                                filterOption={filterSelect}
                                loading={formState.loadingUsers}
                                options={formState.users}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Complaint"
                            name="message"
                            rules={[{required: true, message: 'Please enter the complaint!'}]}
                        >
                            <TextArea rows={6} placeholder="Enter the complaint"/>
                        </Form.Item>
                    </Col>
                    {/* <Col span={8}>
                        <Form.Item
                            label="Date Accepted"
                            name="accepted_at"
                        >
                            <DatePicker format={'YYYY/MM/DD HH:mm'} style={{width: '100%'}} showTime={{ format: 'HH:mm' }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Date Completed"
                            name="completed_at"
                        >
                            <DatePicker format={'YYYY/MM/DD HH:mm'} style={{width: '100%'}} showTime={{ format: 'HH:mm' }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Date Closed"
                            name="closed_at"
                        >
                            <DatePicker format={'YYYY/MM/DD HH:mm'} style={{width: '100%'}} showTime={{ format: 'HH:mm' }} />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            label="Closure Reason"
                            name="closure_reason"
                        >
                            <Input placeholder="Closure Reason" />
                        </Form.Item>
                    </Col> */}
                </Row>
            </Form>
            {formState.contactModalVisible && (
                <ContactModal onAdd={contactAdded}
                              onEdit={contactEdited}
                              contactType="passenger"
                              current={formState.contact}
                              onClose={() => setFormState({...formState, contactModalVisible: false})}/>
            )}
            {formState.locationModalVisible && (
                <LocationModal onAdd={locationAdded}
                               onClose={() => setFormState({...formState, locationModalVisible: false})}/>
            )}
            {formState.operatorModalVisible && (
                <OperatorModal onAdd={operatorAdded}
                               onClose={() => setFormState({...formState, operatorModalVisible: false})}/>
            )}
            {formState.typeModalVisible && (
                <CaseTypeModal onAdd={typeAdded}
                               onClose={() => setFormState({...formState, typeModalVisible: false})}/>
            )}
        </Spin>
    );
})

export default ComplaintForm;

import React, { useContext, useEffect, useState } from 'react';
import Master from '../../../Components/Layouts/Master';
import CaseStatusModal from '../../../Components/CaseStatusModal';
import './index.less';
import { Button, Col, Drawer, Dropdown, Form, Input, Menu, Modal, notification, Row, Table, Tooltip } from "antd";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    FilterOutlined,
    FilterTwoTone,
    QuestionCircleOutlined
} from "@ant-design/icons";
import moment from "moment";
import AjaxService, { getAPIUrl } from "../../../Services/AjaxService";
import { ProfileContext, useCan } from "../../../Contexts/ProfileContext";

function Microsoft(props: any) {

    let isCancelled = false;

    const [authState, setAuthState] = useState({
        loading: true,
        user: false as any,
        expires: false as any
    });

    const fetchData = async () => {
        setAuthState((previous: any) => {
            return {
                ...previous,
                loading: true
            }
        });

        let request: any = {};
        let response: any;
        try {
            response = await AjaxService.get(`microsoft/oauth/token`, request);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setAuthState((previous: any) => {
            return {
                ...previous,
                loading: false,
                user: ('me' in response ? response.me : false),
                expires: ('expires' in response ? response.expires * 1000 : false)
            }
        });
    }

    const authenticate = async () => {
        try {
            let response = await AjaxService.post(`microsoft/oauth/start`, {
                'redirect_uri': getAPIUrl() + `microsoft/oauth/callback`,
                'return_uri': process.env.REACT_APP_PUBLIC_URL + '/settings/microsoft'
            });
            window.location.href = response.redirect;
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        fetchData();
        return () => {
            isCancelled = true;
        };
    }, [props]);

    return (
        <Master className={`Microsoft`}>
            {authState.user !== false && (
                <>
                    <p>Currently authenticated using: <strong>{authState.user.displayName} &lt;{authState.user.mail}&gt;</strong></p>
                </>
            )}
            <button type="button" onClick={(e) => authenticate()}>Authenticate</button>
        </Master>
    );
}

export default Microsoft;
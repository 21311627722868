import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Input, Modal, notification, Row, Select, Spin, Tooltip} from 'antd';
import './index.less';
import AjaxService from "../../Services/AjaxService";
import {PlusOutlined} from "@ant-design/icons";
import ContactModal from "../ContactModal";

function OperatorModal(props: any) {

    const [form] = Form.useForm();

    const [modalState, setModalState] = useState({
        loading: false,
        contacts: [] as any,
        loadingContacts: false,
        contactModalVisible: false,
        error: '',
        search: ''
    });

    const searchContacts = async (search: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                loadingContacts: true,
                contacts: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`contacts`, {
                filter_query: search,
                filter_contact_type: 'operator'
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        setModalState((previous: any) => {
            return {
                ...previous,
                loadingContacts: false,
                contacts: (response ? response.data : previous.contacts)
            };
        });
    }

    const addOperator = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        try {
            let response = await AjaxService.post('operators', values);
            props.onAdd(response.data);
            closeModal();
        } catch (e: any) {
            if ('errors' in e.data && !('title' in e.data.errors)) {
                notification.error({
                    message: 'Add Operator',
                    description: 'There was an issue adding the operator. Please try again.'
                });
            }
            setModalState((previous: any) => {
                return {
                    ...previous,
                    error: ('errors' in e.data && 'title' in e.data.errors ? e.data.errors.title[0] : ''),
                    loading: false
                };
            });
        }
    }

    const editOperator = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        try {
            let response = await AjaxService.put('operators/' + props.current.id, values);
            props.onAdd(response.data);
            closeModal();
        } catch (e: any) {
            if (!('title' in e.data.errors)) {
                notification.error({
                    message: 'Edit Operator',
                    description: 'There was an issue editing the operator. Please try again.'
                });
            }
            setModalState((previous: any) => {
                return {
                    ...previous,
                    error: ('title' in e.data.errors ? e.data.errors.title[0] : ''),
                    loading: false
                };
            });
        }
    }

    const closeModal = async () => {
        setModalState((previous: any) => {
            return {
                ...previous,
                loading: false
            };
        });
        form.resetFields();
        props.onClose();
    }

    const contactAdded = (contact: any) => {
        modalState.contacts.push(contact);
        setModalState({
            ...modalState,
            contacts: [
                ...modalState.contacts
            ],
            contactModalVisible: false
        });
        form.setFieldsValue({
            primary_contact_id: contact.id.toString()
        });
        notification.success({
            message: 'Add Contact',
            description: `The contact was successfully created.`
        });
    }

    useEffect(() => {
        if (props.current) {
            props.current.primary_contact_id = props.current.contact.id.toString();
            form.setFieldsValue(props.current);
        }
    }, [props, form]);

    return (
        <>
            <Modal
                visible
                title={props.current ? 'Edit Operator' : 'Add Operator'}
                onOk={() => form.submit()}
                onCancel={closeModal}
                destroyOnClose
                forceRender
                confirmLoading={modalState.loading}
            >
                <Form
                    form={form}
                    onFinish={(values: any) => {
                        (props.current ? editOperator(values) : addOperator(values));
                    }}
                    layout="vertical"
                >
                    {modalState.error !== '' && (
                        <Alert style={{marginBottom: '1rem'}} message={modalState.error} type="error" showIcon/>
                    )}
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Operator"
                                name="title"
                                rules={[{required: true, message: 'Operator is required!'}]}
                            >
                                <Input placeholder="Operator"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className="ant-form-item-label">
                                <label className="ant-form-item-required">Contact</label>
                            </div>
                            <Input.Group compact>
                                <Form.Item
                                    name="primary_contact_id"
                                    rules={[{required: true, message: 'Please select a contact or create a new one!'}]}
                                    style={{width: "calc(100% - 32px)"}}
                                >
                                    <Select
                                        placeholder="Search for a contact"
                                        showSearch
                                        onSearch={searchContacts}
                                        filterOption={false}
                                        style={{width: '100%'}}
                                        notFoundContent={modalState.loadingContacts ? <Spin size="small"/> : null}
                                        loading={modalState.loadingContacts}
                                    >
                                        {modalState.contacts.length === 0 && props.current && (
                                            <Select.Option className={props.current.contact.has_open_cases ? 'has-open-cases' : ''} key={props.current.contact.id.toString()}
                                                           value={props.current.contact.id.toString()}>
                                                {props.current.contact.first_name} {props.current.contact.last_name}
                                                <small style={{display: 'block'}}>{props.current.contact.email}</small>
                                            </Select.Option>
                                        )}
                                        {modalState.contacts.map((contact: any) => {
                                            return (
                                                <Select.Option className={contact.has_open_cases ? 'has-open-cases' : ''} key={contact.id.toString()}
                                                               value={contact.id.toString()}>
                                                    {contact.first_name} {contact.last_name}
                                                    <small style={{display: 'block'}}>{contact.email}</small>
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Tooltip title="Add Contact">
                                    <Button icon={<PlusOutlined/>}
                                            onClick={() => setModalState({...modalState, contactModalVisible: true})}/>
                                </Tooltip>
                            </Input.Group>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Vehicle Count"
                                name="vehicle_count"
                            >
                                <Input type="number" min="0" step="1" placeholder="Vehicle Count"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Average Annual Mileage"
                                name="vehicle_mileage"
                            >
                                <Input type="number" min="0" step="1" placeholder="Average Annual Mileage"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {modalState.contactModalVisible && (
                <ContactModal onAdd={contactAdded}
                              contactType="operator"
                              onClose={() => setModalState({...modalState, contactModalVisible: false})}/>
            )}
        </>
    );
}

export default OperatorModal;
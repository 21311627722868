import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Button, Cascader, Col, DatePicker, Form, Input, notification, Row, Select, Spin, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import ContactModal from '../../../../Components/ContactModal';
import LocationModal from '../../../../Components/LocationModal';
import OperatorModal from '../../../../Components/OperatorModal';
import './index.less';
import AjaxService from "../../../../Services/AjaxService";
import { debounce } from 'lodash';

const { TextArea } = Input;

const EnquiryForm = React.forwardRef((props: any, ref: any) => {

    let isCancelled: any = false;

    const [form] = Form.useForm();
    const { Option } = Select;

    const [formState, setFormState] = useState({
        ticket: false as any,
        ticketLoading: (props.action === 'edit'),
        locations: [] as any,
        loadingLocations: true,
        locationModalVisible: false as any,
        contacts: [] as any,
        loadingContacts: false,
        contactModalVisible: false,
        selectedContact: false as any,
        operators: [] as any,
        loadingOperators: true,
        operatorModalVisible: false,
        caseStatuses: [] as any,
        loadingCaseStatuses: true,
        caseStatusSearch: '',
        types: [] as any,
        loadingTypes: true,
        typeSearch: '',
        users: [] as any,
        loadingUsers: true,
        complaints: [] as any,
        loadingComplaints: false,
        classifications: [] as any,
        loadingClassifications: true,
        classificationSearch: '',
        allCases: false as any,
        contactSearch: ''
    });

    const contactAdded = (contact: any) => {
        formState.contacts.push(contact);
        setFormState({
            ...formState,
            contacts: [
                ...formState.contacts
            ],
            contactModalVisible: false
        });
        form.setFieldsValue({
            contact_id: contact.id.toString()
        });
        notification.success({
            message: 'Add Contact',
            description: `The contact was successfully created.`
        });
    }

    const operatorAdded = (operator: any) => {
        formState.operators.push({
            label: operator.title,
            value: operator.id
        });
        setFormState({
            ...formState,
            operators: [
                ...formState.operators
            ],
            operatorModalVisible: false
        });
        form.setFieldsValue({
            operator_id: operator.id
        });
        notification.success({
            message: 'Add Operator',
            description: `The operator was successfully created..`
        });
    }

    const locationAdded = (location: any) => {
        formState.locations.forEach((tmp: any, idx: any) => {
            if (tmp.value == location.region_id) {
                formState.locations[idx].children.push({
                    label: location.title,
                    value: location.id,
                    children: []
                });
            }
        });
        setFormState({
            ...formState,
            loadingLocations: true,
            locations: [
                ...formState.locations
            ],
        });
        form.setFieldsValue({
            location_id: (location ? [location.region_id, location.id] : [])
        });
        fetchLocationTree((location ? [location.region_id, location.id] : []));
        notification.success({
            message: 'Add Location',
            description: `The location was successfully created.`
        });
    }

    const fetchLocationTree = async (selected_id: any = null) => {
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingLocations: true,
                locations: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`locations/tree`, {});
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingLocations: false,
                locations: (response ? response : previous.locations)
            };
        });
        if (response && selected_id) {
            form.setFieldsValue({
                location_id: selected_id
            });
        }
    }

    const fetchOperators = async () => {
        try {
            let response = await AjaxService.get(`operators/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingOperators: false,
                    operators: response.data.map((operator: any) => {
                        return {
                            label: operator.title,
                            value: operator.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchCaseStatuses = async () => {
        try {
            let response = await AjaxService.get(`case-statuses/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingCaseStatuses: false,
                    caseStatuses: response.data.map((status: any) => {
                        return {
                            label: status.title,
                            value: status.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchClassifications = async () => {
        try {
            let response = await AjaxService.get(`cases/meta/`, {
                filter_case_type: 'enquiry',
                filter_key: 'Classification'
            }, true);
            setFormState((previous: any) => {
                let classifications: any = response.data.reduce((acc: any, classification: any) => {
                    if (acc.indexOf(classification) === -1) {
                        acc.push(classification);
                    }
                    return acc;
                }, [
                    'Enquiry England',
                    'Enquiry Scotland',
                    'Enquiry Wales',
                    'English 1st Line',
                    'Welsh 1st Line',
                    'Scottish 1st Line',
                    'Other Website Misread'
                ]).map(function (classification: any) {
                    return {
                        value: classification,
                        label: classification
                    };
                });
                return {
                    ...previous,
                    loadingClassifications: false,
                    classifications
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchTypes = async () => {
        try {
            let response = await AjaxService.get(`cases/meta/`, {
                filter_case_type: 'enquiry',
                filter_key: 'Type'
            }, true);
            setFormState((previous: any) => {
                let types: any = response.data.reduce((acc: any, type: any) => {
                    if (acc.indexOf(type) === -1) {
                        acc.push(type);
                    }
                    return acc;
                }, [
                    'Phone', 
                    'E-mail', 
                    'Post'
                ]).map(function (type: any) {
                    return {
                        value: type,
                        label: type
                    };
                });
                return {
                    ...previous,
                    loadingTypes: false,
                    types
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const searchContacts = async (search: any) => {
        if (!search || search.lenth < 4) {
            return;
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                contactSearch: search,
                loadingContacts: true,
                contacts: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`contacts`, { filter_query: search }, true);
            // if (response.data && search) {
            //     response.data.push({
            //         address_line_1: "",
            //         address_line_2: "",
            //         case_count: 0,
            //         county: "",
            //         email: "",
            //         first_name: search,
            //         id: search,
            //         last_name: "[New]",
            //         operator_count: 0,
            //         postcode: "",
            //         telephone: "",
            //         town: ""
            //     });
            // }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingContacts: false,
                contacts: (response ? response.data : previous.contacts)
            };
        });
    }

    const searchComplaints = async (search: any) => {
        if (!search || search.length < 4) {
            return;
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingComplaints: true,
                complaints: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`cases`, {
                filter_query: search,
                filter_type: 'complaint'
            }, true);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingComplaints: false,
                complaints: (response ? response.data : previous.complaints)
            };
        });
    }

    const fetchUsers = async () => {
        try {
            let response = await AjaxService.get(`users/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingUsers: false,
                    users: response.data.map((user: any) => {
                        return {
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchCase = async () => {
        let response: any;
        try {
            console.log('Enquiry/Form: fetching data...');
            response = await AjaxService.get(`cases/${props.id}`, { 'include': ['meta'], 'initial_response': true });
            let crossBorder = Number.parseInt(response.data.meta['Cross Border']);
            if (isNaN(crossBorder)) {
                crossBorder = 0;
            }
            form.setFieldsValue({
                contact_id: response.data.contact?.id.toString(),
                // contact: response.data.contact,
                location_id: (response.data.location ? [response.data.location.region_id, response.data.location.id] : null),
                // location: response.data.location,
                'meta:Name': response.data.meta['Name'],
                'meta:Cross Border': crossBorder,
                'meta:Classification': response.data.meta['Classification'],
                'meta:Out of Remit Code': response.data.meta['Out of Remit Code'],
                'meta:Type': response.data.meta['Type'],
                operator_id: response.data.operator?.id,
                // operator: response.data.operator,
                'meta:Complaint': response.data.meta['Complaint']?.id,
                status_id: Number.parseInt(response.data.status.id),
                'meta:Date': (response.data.meta['Date'] ? moment(response.data.meta['Date']) : null),
                assigned_to: response.data.assigned_to?.id,
                message: response.data.initial_response
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                ticket: (response ? response.data : previous.ticket),
                ticketLoading: false
            }
        });
    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        form.resetFields();
        if (props.action === 'edit') {
            fetchCase();
        }
        fetchLocationTree();
        fetchOperators();
        fetchCaseStatuses();
        fetchUsers();
        fetchClassifications();
        fetchTypes();
        return () => {
            isCancelled = true;
        };
    }, []);

    const filterSelect = (input: any, option: any) => {
        let value = ('children' in option ? option.children : option.label);
        if (Array.isArray(value)) {
            value = value.join('');
        }
        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const onSubmit = async (values: any) => {

        try {
            let body = JSON.parse(JSON.stringify(values));
            if (body.location_id) {
                body.location_id = body.location_id.pop();
            }
            body.type = 'enquiry';

            let response;
            if (props.action === 'edit') {
                response = await AjaxService.put(`cases/${props.id}`, body);
            } else {
                response = await AjaxService.post('cases', body);
            }
            props.onAdd(response.data.id, (response.data.reference ? response.data.reference : response.data.id), response.data.type.value, props.action);
        } catch (e: any) {
            notification.error({
                message: (props.action === 'edit' ? 'Edit' : 'Add') + ' Enquiry',
                description: 'There was an issue ' + (props.action === 'edit' ? 'updat' : 'creat') + 'ing the case. Please try again.'
            });
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loading: false
                };
            });
        }

    }

    useImperativeHandle(ref, () => ({
        submitForm() {
            form.submit();
        }
    }));

    return (
        <Spin spinning={formState.ticketLoading}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onSubmit}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        {/* <Form.Item
                          label="Name"
                          name="meta:Name"
                          rules={[{required: false, message: 'Please enter the contact!'}]}
                        >
                            <Input placeholder="Name"/>
                        </Form.Item> */}
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-required">Contact</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="contact_id"
                                rules={[{ required: true, message: 'Please select a contact or create a new one!' }]}
                                style={{ width: "calc(100% - 32px)" }}
                            >
                                <Select
                                    placeholder="Search for a contact"
                                    showSearch
                                    onSearch={debounce(searchContacts, 300)}
                                    filterOption={false}
                                    style={{ width: '100%' }}
                                    notFoundContent={formState.loadingContacts ? <Spin size="small" /> : null}
                                    loading={formState.loadingContacts}
                                    onSelect={() => setFormState({...formState, contactSearch: ''})}
                                >
                                    {formState.contacts.length === 0 && props.action === 'edit' && formState.ticket?.contact && (
                                        <Option key={formState.ticket.contact.id.toString()}
                                            value={formState.ticket.contact.id.toString()}>
                                            <div className={formState.ticket.contact.has_open_cases ? 'has-open-cases' : ''}>
                                                {formState.ticket.contact.first_name} {formState.ticket.contact.last_name}
                                                <small style={{ display: 'block' }}>{formState.ticket.contact.email}</small>
                                            </div>
                                        </Option>
                                    )}
                                    {formState.contacts.map((contact: any) => {
                                        return (
                                            <Option key={contact.id.toString()} value={contact.id.toString()}>
                                                <div className={contact.has_open_cases ? 'has-open-cases' : ''}>
                                                    {contact.first_name} {contact.last_name}
                                                    <small style={{ display: 'block' }}>{contact.email}</small>
                                                </div>
                                            </Option>
                                        );
                                    })}
                                    {formState.contactSearch !== '' && (
                                        <Option key={'search'} value={formState.contactSearch}>
                                            Quick Add &quot;{formState.contactSearch}&quot;
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Tooltip title="Add Contact">
                                <Button icon={<PlusOutlined />}
                                    onClick={() => setFormState({ ...formState, contactModalVisible: true })} />
                            </Tooltip>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-requiredx">Location</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="location_id"
                                rules={[{ required: false, message: 'Please select a location or create a new one!' }]}
                                style={{ width: "calc(100% - 32px)" }}
                            >
                                <Cascader
                                    disabled={formState.loadingLocations}
                                    options={formState.locations}
                                    style={{ width: '100%' }}
                                    showSearch={{
                                        filter: (inputValue, path) => {
                                            return path.some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
                                        }
                                    }}
                                    placeholder="Search for a location"
                                />
                            </Form.Item>
                            <Tooltip title="Add Location">
                                <Button disabled={formState.loadingLocations} icon={<PlusOutlined />}
                                    onClick={() => setFormState({ ...formState, locationModalVisible: true })} />
                            </Tooltip>
                        </Input.Group>
                        {/* <Form.Item
                          label="Location"
                          name="location"
                          rules={[{required: false, message: 'Please enter the contact!'}]}
                        >
                            <Input placeholder="Location"/>
                        </Form.Item> */}
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Cross Border"
                            name="meta:Cross Border"
                            initialValue={0}
                            rules={[{required: false, message: 'Please specify if this journey was across borders!'}]}
                        >
                            <Select
                                style={{width: '100%'}}
                            >
                                <Select.Option key={0} value={0}>No</Select.Option>
                                <Select.Option key={1} value={1}>Yes</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Classification"
                            name="meta:Classification"
                            rules={[{ required: false, message: 'Please select a classification or create a new one!' }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                onSearch={(value: any) => setFormState({ ...formState, classificationSearch: value })}
                                filterOption={filterSelect}
                                loading={formState.loadingClassifications}
                                onChange={(value: any, option: any) => {
                                    if (option.key === 'custom') {
                                        setFormState({
                                            ...formState,
                                            classificationSearch: '',
                                            classifications: [
                                                ...formState.classifications, {
                                                    value: value,
                                                    label: value
                                                }
                                            ]
                                        });
                                        form.setFieldsValue({
                                            'meta:Classification': value
                                        });
                                    }
                                }}
                                placeholder="Search for a classification"
                            >
                                {formState.classifications && formState.classifications.map((classification: any) => {
                                    return <Select.Option key={classification.value}
                                        value={classification.value}>{classification.label}</Select.Option>
                                })}
                                {formState.classificationSearch && (
                                    <Select.Option key="custom"
                                        value={formState.classificationSearch}>Add &ldquo;{formState.classificationSearch}&rdquo;</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Type"
                            name="meta:Type"
                            rules={[{ required: false, message: 'Please select a type or create a new one!' }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                onSearch={(value: any) => setFormState({ ...formState, typeSearch: value })}
                                filterOption={filterSelect}
                                loading={formState.loadingTypes}
                                onChange={(value: any, option: any) => {
                                    if (option.key === 'custom') {
                                        setFormState({
                                            ...formState,
                                            typeSearch: '',
                                            types: [
                                                ...formState.types, {
                                                    value: value,
                                                    label: value
                                                }
                                            ]
                                        });
                                        form.setFieldsValue({
                                            'meta:Type': value
                                        });
                                    }
                                }}
                                placeholder="Search for a type"
                            >
                                {formState.types && formState.types.map((type: any) => {
                                    return <Select.Option key={type.value}
                                        value={type.value}>{type.label}</Select.Option>
                                })}
                                {formState.typeSearch && (
                                    <Select.Option key="custom"
                                        value={formState.typeSearch}>Add &ldquo;{formState.typeSearch}&rdquo;</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-requiredx">Operator</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="operator_id"
                                rules={[{ required: false, message: 'Please select a operator or create a new one!' }]}
                                style={{ width: "calc(100% - 32px)" }}
                            >
                                <Select
                                    placeholder="Search for a operator"
                                    style={{ width: '100%' }}
                                    showSearch
                                    filterOption={filterSelect}
                                    loading={formState.loadingOperators}
                                    options={formState.operators}
                                />
                            </Form.Item>
                            <Tooltip title="Add Operator">
                                <Button icon={<PlusOutlined />}
                                    onClick={() => setFormState({ ...formState, operatorModalVisible: true })} />
                            </Tooltip>
                        </Input.Group>
                        {/* <Form.Item
                          label="Operator"
                          name="operator"
                          rules={[{required: false, message: 'Please enter the contact!'}]}
                        >
                            <Input placeholder="Operator"/>
                        </Form.Item> */}
                    </Col>
                    <Col span={12}>
                        <Form.Item
                          label="Out of Remit Code"
                          name="meta:Out of Remit Code"
                          rules={[{required: false, message: 'Please enter the contact!'}]}
                        >
                            <Input placeholder="Out of Remit Code"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Complaint"
                            name="meta:Complaint"
                            rules={[{ required: false, message: 'Please select a complaint!' }]}
                        >
                            <Select
                                placeholder="Search for a complaint"
                                showSearch
                                onSearch={debounce(searchComplaints, 300)}
                                filterOption={false}
                                notFoundContent={formState.loadingComplaints ? <Spin size="small" /> : null}
                                loading={formState.loadingComplaints}
                            >
                                {formState.complaints.length === 0 && props.action === 'edit' && formState.ticket && formState.ticket.meta['Complaint'] && (
                                    <Option key={formState.ticket?.meta['Complaint'].id}
                                        value={formState.ticket?.meta['Complaint'].id}>
                                        <span
                                            className={`TrafficLight Is-${formState.ticket?.meta['Complaint'].traffic_light}`} /> {formState.ticket?.meta['Complaint'].reference} - {formState.ticket?.meta['Complaint'].contact?.first_name} {formState.ticket?.meta['Complaint'].contact?.last_name}
                                        {/* className={`TrafficLight Is-${formState.ticket?.meta['Complaint'].traffic_light}`}/> #{formState.ticket?.meta['Complaint'].id} - {formState.ticket?.meta['Complaint'].contact} */}
                                        <small
                                            style={{ display: 'block' }}>{formState.ticket?.meta['Complaint'].operator.title}</small>
                                        {/* style={{display: 'block'}}>{formState.ticket?.meta['Complaint'].operator}</small> */}
                                    </Option>
                                )}
                                {formState.complaints.map((item: any) => (
                                    <Option key={item.id} value={item.id}>
                                        <span
                                            className={`TrafficLight Is-${item.traffic_light}`} /> {item.reference ?? '#' + item.id} - {item.contact.first_name} {item.contact.last_name}
                                        {/* className={`TrafficLight Is-${item.traffic_light}`}/> #{item.id} - {item.contact} */}
                                        <small style={{ display: 'block' }}>{item.operator && item.operator?.title}</small>
                                        {/* <small style={{display: 'block'}}>{item.operator}</small> */}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                        <Form.Item
                          label="Contact"
                          name="contact"
                          rules={[{required: false, message: 'Please enter the contact!'}]}
                        >
                            <Input placeholder="Contact"/>
                        </Form.Item>
                    </Col> */}
                    <Col span={12}>
                        <Form.Item
                            label="Status"
                            name="status_id"
                            initialValue={1}
                            rules={[{ required: true, message: 'Please select a status or create a new one!' }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                onSearch={(value: any) => setFormState({ ...formState, caseStatusSearch: value })}
                                filterOption={filterSelect}
                                loading={formState.loadingCaseStatuses}
                                onChange={(value: any, option: any) => {
                                    if (option.key === 'custom') {
                                        setFormState({
                                            ...formState,
                                            caseStatusSearch: '',
                                            caseStatuses: [
                                                ...formState.caseStatuses, {
                                                    value: Number.parseInt(value),
                                                    label: value
                                                }
                                            ]
                                        });
                                        form.setFieldsValue({
                                            'status_id': Number.parseInt(value)
                                        });
                                    }
                                }}
                                placeholder="Search for a status"
                            >
                                {formState.caseStatuses && formState.caseStatuses.map((classification: any) => {
                                    return <Select.Option key={classification.value}
                                        value={classification.value}>{classification.label}</Select.Option>
                                })}
                                {formState.caseStatusSearch && (
                                    <Select.Option key="custom"
                                        value={formState.caseStatusSearch}>Add &ldquo;{formState.caseStatusSearch}&rdquo;</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Date"
                            name="meta:Date"
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Assigned To"
                            name="assigned_to"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Search for a user"
                                filterOption={filterSelect}
                                loading={formState.loadingUsers}
                                options={formState.users}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Enquiry"
                            name="message"
                            rules={[{ required: true, message: 'Please enter the enquiry!' }]}
                        >
                            <TextArea rows={6} placeholder="Enter the enquiry" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {formState.contactModalVisible && (
                <ContactModal onAdd={contactAdded}
                    contactType="passenger"
                    onClose={() => setFormState({ ...formState, contactModalVisible: false })} />
            )}
            {formState.locationModalVisible && (
                <LocationModal onAdd={locationAdded}
                    onClose={() => setFormState({ ...formState, locationModalVisible: false })} />
            )}
            {formState.operatorModalVisible && (
                <OperatorModal onAdd={operatorAdded}
                    onClose={() => setFormState({ ...formState, operatorModalVisible: false })} />
            )}
        </Spin>
    );
})

export default EnquiryForm;

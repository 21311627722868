import React, {useContext, useEffect, useState} from 'react';
import Master from '../../../Components/Layouts/Master';
import './index.less';
import {
    Alert,
    Button,
    Col,
    Divider,
    Drawer,
    Dropdown,
    Form,
    Input,
    Menu,
    Modal,
    notification,
    Row,
    Select,
    Table,
    Transfer
} from "antd";
import {DeleteOutlined, DownOutlined, EditOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import AjaxService from "../../../Services/AjaxService";
import {ProfileContext, useCan} from "../../../Contexts/ProfileContext";
import PermissionTree from "../../../Components/PermissionTree";
import TextArea from 'antd/lib/input/TextArea';

function List(props: any) {

    let isCancelled = false;

    const [form] = Form.useForm();
    const {Option} = Select;

    const {profile} = useContext(ProfileContext);
    const {can, canAny} = useCan(profile);

    const [tableState, setTableState] = useState({
        loading: true,
        selectedRows: [],
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sorter: {
            field: 'created_at',
            order: 'descend'
        },
        filterActive: false,
        filters: {
            query: ''
        },
        data: [] as any
    });

    const [formState, setFormState] = useState({
        visible: false,
        loading: false,
        title: '',
        error: '',
        current: false as any,
        permissions: [],
        roles: [],
        loadingPermissions: false,
        loadingRoles: false
    });

    const deleteSingle = (user: any) => {
        Modal.confirm({
            title: `Delete User`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to delete this user?',
            onOk() {
                AjaxService.delete(`users/${user.id}`,).then((result) => {
                    if (result !== false) {
                        notification.success({
                            message: 'Delete User',
                            description: 'The user has been successfully deleted.'
                        });
                    }
                    fetchData({...tableState, selectedRows: [], loading: true});
                }).catch((e: any) => {
                    notification.error({
                        message: 'Delete User',
                        description: e.message
                    });
                    fetchData({...tableState, selectedRows: [], loading: false});
                });
            },
            onCancel() {
            },
        });
    }

    const fetchRoles = async () => {
        setFormState((previous: any) => {
            return {...previous, loadingRoles: true};
        });
        let response: any;
        try {
            response = await AjaxService.get('roles');
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingRoles: false,
                roles: (response ? response.data : previous.roles)
            };
        });
    }

    const fetchPermissions = async () => {
        setFormState((previous: any) => {
            return {...previous, loadingPermissions: true};
        });
        let response: any;
        try {
            response = await AjaxService.get('permissions');
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingPermissions: false,
                permissions: (response ? response.data : previous.permissions)
            };
        });
    }

    const fetchData = async (params: any = tableState) => {
        setTableState((previous: any) => {
            return {
                ...previous,
                loading: true
            }
        });

        let request: any = {
            mode: 'advanced'
        };

        if ('pagination' in params && Object.keys(params.pagination).length > 0) {
            request.page = params.pagination.current;
            request.limit = params.pagination.pageSize;
        }

        if ('sorter' in params && 'field' in params.sorter) {
            request.sort = (typeof params.sorter.field === 'string' ? params.sorter.field : params.sorter.field.join('.'));
            if (params.sorter.order) {
                request.direction = (params.sorter.order === 'descend' ? 'desc' : 'asc');
            }
        }

        if ('filters' in params && Object.keys(params.filters).length > 0) {
            Object.keys(params.filters).forEach((key: any) => {
                if (params.filters[key]) {
                    request['filter_' + key] = params.filters[key];
                }
            });
        }

        let response: any;
        try {
            response = await AjaxService.get(`users/`, request);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setTableState((previous: any) => {
            if (!('filters' in params)) {
                params.filters = previous.filters;
            }
            if (!('sorter' in params)) {
                params.sorter = previous.sorter;
            }
            if (!('pagination' in params)) {
                params.pagination = previous.pagination;
            }
            if (!('filterActive' in params)) {
                params.filterActive = previous.filterActive;
            }
            if (!response) {
                return {...previous, loading: false}
            }
            return {
                ...previous,
                ...params,
                loading: false,
                data: response.data,
                pagination: {
                    ...params.pagination,
                    total: response.meta.total
                }
            }
        });
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchData({
            ...tableState,
            pagination,
            sorter
        });
    }

    const editUser = async (user: any) => {

        try {
            let response = await AjaxService.get('users/' + user.id, {
                mode: 'advanced'
            });
            setFormState({...formState, visible: true, title: 'Edit User', current: response.data});
            form.setFieldsValue({
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email: response.data.email,
                region: response.data.region,
                signature: response.data.signature,
                roles: Object.keys(response.data.roles).map(v => v.toString()),
                permissions: Object.keys(response.data.permissions).map(v => v.toString())
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const columns = [{
        title: 'Name',
        key: 'name',
        render: (text: any, row: any) => {
            return <span className="clickable" onClick={() => editUser(row)}>{row.first_name} {row.last_name}</span>;
        }
    }, {
        title: 'Email',
        key: 'email',
        dataIndex: 'email'
    }, {
        title: 'Region',
        key: 'region',
        dataIndex: 'region',
        render: (text: any) => (text || 'N/A')
    }, {
        title: 'Roles',
        key: 'roles',
        dataIndex: 'roles',
        render: (text: any) => Object.keys(text).map((r: any) => text[r]).join(', ')
    }, {
        title: 'Created',
        dataIndex: 'created_at',
        sorter: true,
        render: (text: any, record: any, index: any) => moment(text).format('DD/MM/YYYY HH:mm')
    }, (canAny(['users.edit', 'users.delete']) ? {
        title: '',
        key: 'actions',
        align: 'right' as 'right',
        render: (text: any, row: any) => <Dropdown overlay={userMenu(row)} placement="bottomRight">
            <Button icon={<DownOutlined/>}/>
        </Dropdown>
    } : null)];

    const userMenu = (row: any, viewing: any = false) => (
        <Menu>
            {can('users.edit') && (
                <Menu.Item key="edit" onClick={() => editUser(row)} icon={<EditOutlined/>}>Edit</Menu.Item>
            )}
            {row.id !== 0 && profile && row.id !== profile.id && can('users.delete') && (
                <Menu.Item key="delete" onClick={() => deleteSingle(row)} icon={<DeleteOutlined/>}>Delete</Menu.Item>
            )}
        </Menu>
    );

    const closeForm = () => {
        form.resetFields();
        setFormState({...formState, visible: false, loading: false});
    }

    const addUser = () => {
        form.resetFields();
        setFormState({...formState, visible: true, title: 'Add User'});
    }

    const onSubmit = async (values: any) => {
        setFormState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        let response: any;
        try {
            if (formState.current) {
                response = await AjaxService.put('users/' + formState.current.id, values);
            } else {
                response = await AjaxService.post('users', values);
            }
        } catch (e: any) {
            if (!('email' in e.data.errors)) {
                notification.error({
                    message: (formState.current ? 'Edit' : 'Add') + ' User',
                    description: 'There was an issue ' + (formState.current ? 'updat' : 'creat') + 'ing the user. Please try again.'
                });
            }
            setFormState((previous: any) => {
                return {
                    ...previous,
                    error: ('email' in e.data.errors ? e.data.errors.email[0] : ''),
                    loading: false
                };
            });
        }

        if (response) {
            notification.success({
                message: (formState.current ? 'Edit' : 'Add') + ' User',
                description: 'The user was successfully ' + (formState.current ? 'updat' : 'creat') + 'ed.'
            });
            setFormState({...formState, current: false, visible: false});
            form.resetFields();
            fetchData();
        }

    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        fetchData();
        return () => {
            isCancelled = true;
        };
    }, []);

    useEffect(() => {
        if (formState.visible && formState.roles.length === 0) {
            if (can('users.user_roles')) {
                fetchRoles();
            }
            if (can('users.user_permissions')) {
                fetchPermissions();
            }
        }
    }, [formState.visible]);

    return (
        <Master className={`UsersList`}>
            {canAny(['users.add', 'users.edit']) && (
                <Drawer
                    title={formState.title}
                    visible={formState.visible}
                    closable={true}
                    destroyOnClose
                    forceRender
                    onClose={() => setFormState({...formState, visible: false, loading: false})}
                    footer={
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Button loading={formState.loading} onClick={() => closeForm()}>
                                Discard
                            </Button>
                            <Button loading={formState.loading} type="primary" onClick={() => form.submit()}
                                    style={{marginLeft: 'auto'}}>
                                Save
                            </Button>
                        </div>
                    }
                >
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onSubmit}
                        className="UserForm"
                        initialValues={{
                            permissions: [],
                            roles: formState.roles.reduce((acc: any, item: any) => {
                                if (item.name === 'Everyone') {
                                    acc.push(item.id.toString());
                                }
                                return acc;
                            }, [])
                        }}
                    >
                        {formState.error !== '' && (
                            <Alert style={{marginBottom: '1rem'}} message={formState.error} type="error" showIcon/>
                        )}
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="first_name"
                                    label="First Name"
                                    rules={[{required: true, message: 'First Name is required!'}]}
                                >
                                    <Input placeholder="First Name"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="last_name"
                                    label="Last Name"
                                    rules={[{required: true, message: 'Last Name is required!'}]}
                                >
                                    <Input placeholder="Last Name"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label="Email Address"
                                    rules={[
                                        {required: true, message: 'Email Address is required!'},
                                        {type: 'email', message: 'Invalid email address!'},
                                    ]}
                                >
                                    <Input placeholder="Email Address" type="email"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={(formState.current ? [] : [
                                        {required: true, message: 'Password is required!'},
                                        {min: 6, message: 'Password must be at least 6 characters!'}
                                    ])}
                                >
                                    <Input.Password placeholder="Password"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="region"
                                    label="Region"
                                >
                                    <Select
                                        placeholder="Select a region"
                                        style={{width: '100%'}}
                                        allowClear
                                    >
                                        <Option key="England" value="England">England</Option>
                                        <Option key="Scotland" value="Scotland">Scotland</Option>
                                        <Option key="Wales" value="Wales">Wales</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="signature"
                                    label="Signature"
                                    help="This will be automatically added to the bottom of emails sent from the system."
                                >
                                    <TextArea rows={8} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                {can('users.user_roles') && (
                                    <>
                                        <Divider>Roles</Divider>
                                        <Form.Item
                                            name="roles"
                                            valuePropName="targetKeys"
                                        >
                                            <Transfer
                                                listStyle={{width: '100%'}}
                                                titles={['Available', 'Assigned']}
                                                dataSource={formState.roles.map((role: any) => {
                                                    return {
                                                        key: role.id.toString(),
                                                        title: role.name
                                                    };
                                                })}
                                                render={item => item.title}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                                {can('users.user_permissions') && (
                                    <>
                                        <Divider>Permissions</Divider>
                                        <Form.Item
                                            name="permissions"
                                        >
                                            <PermissionTree permissions={formState.permissions}/>
                                        </Form.Item>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            )}
            <div className="CaseTableActions">
                <span className="CaseTableTitle">Users</span>
                {can('users.add') && (
                    <Button style={{marginLeft: '.5rem'}} onClick={() => addUser()}>Add User</Button>
                )}
            </div>
            <Table
                className="UserTable"
                columns={columns.filter(c => c != null) as any}
                dataSource={tableState.data}
                loading={tableState.loading}
                rowKey="id"
                onChange={handleTableChange}
                pagination={tableState.pagination}
            />
            {can('users.add') && (
                <Button className={tableState.data.length ? 'PaginationButton' : 'mt-15'} onClick={() => addUser()}>Add
                    User</Button>
            )}
        </Master>
    );
}

export default List;
import React, {useContext, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Auth from '../../../Components/Layouts/Auth';
import {Alert, Button, Form, Input, notification} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import AjaxService from '../../../Services/AjaxService';
import './index.less';
import {ProfileContext} from "../../../Contexts/ProfileContext";

function Forgotten() {

    const [formState, setFormState] = useState({
        loading: false,
        errors: {} as any
    });

    const history = useHistory();
    const {updateProfile} =  useContext(ProfileContext);

    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const onFinish = async (values: any) => {
        try {
            setFormState({...formState, errors: {}, loading: true});
            values.reset_url = process.env.REACT_APP_PUBLIC_URL + '/';
            const result = await AjaxService.post('users/recover', values);
            history.push('/login');
            notification.success({
                message: 'Password Reset Requested',
                description: 'If we were able to locate your account, you will receive an email with instructions on how to reset your password.'
            });
        } catch (e: any) {
            setFormState({
                ...formState, loading: false, errors: ('errors' in e.data ? e.data.errors : {
                    generic: ['An error has occurred, please try again']
                })
            });
        }
    };

    return (
        <Auth>
            <Form
                {...layout}
                name="forgotten"
                onFinish={onFinish}
            >
                {'generic' in formState.errors && formState.errors.generic && (
                    <Alert
                        message={formState.errors.generic[0]}
                        type="error"
                    />
                )}
                <Form.Item
                    name="email"
                    rules={[
                        {required: true, message: 'Please input your email address!'},
                        {type: 'email', message: 'Your email address is invalid!'},
                    ]}
                    validateStatus={('email' in formState.errors && formState.errors.email ? 'error' : undefined)}
                    help={('email' in formState.errors && formState.errors.email ? formState.errors.email[0] : undefined)}
                >
                    <Input type="email" autoComplete="off" placeholder="Email address" prefix={<UserOutlined/>}/>
                </Form.Item>

                <Form.Item>
                    <Button loading={formState.loading} type="primary" block={true} htmlType="submit">
                        {formState.loading ? 'Checking' : 'Request Password Reset'}
                    </Button>
                    <Link id="login" to="/login">Login</Link>
                </Form.Item>
            </Form>
        </Auth>
    );
}

export default Forgotten;
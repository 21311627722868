import React, {useContext, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import Auth from '../../../Components/Layouts/Auth';
import {Alert, Button, Form, Input, notification} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import AjaxService from '../../../Services/AjaxService';
import './index.less';

function Reset() {

    const [formState, setFormState] = useState({
        loading: false,
        errors: {} as any
    });

    const history = useHistory();
    const params = useParams();

    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const onFinish = async (values: any) => {
        try {
            setFormState({...formState, errors: {}, loading: true});
            values.token = (params as any).token;
            const result = await AjaxService.post('users/reset', values);
            if (result.success) {
                history.push('/login');
                notification.success({
                    message: 'Password Reset',
                    description: 'Your password was successfully reset, now please login.'
                });
            } else {
                history.push('/forgotten');
                notification.error({
                    message: 'Password Reset',
                    description: 'Your request was invalid. Please request a new password reset.'
                });
            }
        } catch (e: any) {
            setFormState({
                ...formState, loading: false, errors: ('errors' in e.data ? e.data.errors : {
                    generic: ['An error has occurred, please try again']
                })
            });
        }
    };

    return (
        <Auth>
            <Form
                {...layout}
                name="reset"
                onFinish={onFinish}
            >
                {'generic' in formState.errors && formState.errors.generic && (
                    <Alert
                        message={formState.errors.generic[0]}
                        type="error"
                    />
                )}

                <Form.Item
                    name="password"
                    rules={[{required: true, message: 'Please input your password!'}]}
                >
                    <Input.Password autoComplete="off" placeholder="Password" prefix={<LockOutlined/>}/>
                </Form.Item>

                <Form.Item
                    name="password_confirmation"
                    dependencies={['password']}
                    rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                          },
                        }),
                      ]}
                >
                    <Input.Password autoComplete="off" placeholder="Confirm Password" prefix={<LockOutlined/>}/>
                </Form.Item>

                <Form.Item>
                    <Button loading={formState.loading} type="primary" block={true} htmlType="submit">
                        {formState.loading ? 'Checking' : 'Reset Password'}
                    </Button>
                    <Link id="login" to="/login">Login</Link>
                </Form.Item>
            </Form>
        </Auth>
    );
}

export default Reset;